import { useEffect, useRef } from "react";
import App from "./App";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { getShop } from "./features/shop/shop-slice";
import ErrorPage from "./layouts/404/404";
import Loader from "./layouts/StoreFront/components/Loader";
import { useSetIdentity } from "./hooks/useSetIdentity";
import Notification from "./notification/Notification";

const Root = () => {
  const dispatch = useAppDispatch();
  const { loading, shop } = useAppSelector((state) => state.shop);
  const init = useRef({ dispatch });
  useSetIdentity();

  useEffect(() => {
    const { dispatch } = init.current;
    if (!shop) {
      dispatch(
        getShop({
          filter: {
            slug: {
              eq: `${window.location.host
                .split(".")
                .slice(
                  0,
                  window.location.href.includes("localhost") ? -1 : -2
                )}`,
            },
          },
        })
      );
    }
  }, [shop]);

  if (loading) return <Loader />;
  if (!shop && !loading) return <ErrorPage />;

  return (
    <>
      <Notification />
      <App />
    </>
  );
};

export default Root;
