import { useCallback, useRef } from "react";
import { useAppDispatch } from "../app/hooks";
import {
  dismissNotification,
  displayNotification,
  NotificationPayload,
} from "../features/app/app-slice";

export const useNotification = () => {
  const dispatch = useAppDispatch();
  const init = useRef({ dispatch });

  const notify = useCallback((args: Omit<NotificationPayload, "id">) => {
    const { dispatch } = init.current;
    const id = crypto.randomUUID();
    dispatch(
      displayNotification({
        showNotification: true,
        notification: { ...args, id, timeout: 5 },
      })
    );

    return id;
  }, []);

  const dismiss = useCallback((id: string) => {
    const { dispatch } = init.current;
    dispatch(dismissNotification({ id }));
  }, []);

  return { notify, dismiss };
};
