import { ArrowRightIcon, CheckIcon } from "@heroicons/react/24/outline";
import TextInput from "../../components/TextInput/TextInput";
import {
  CustomerSignUpMutationVariables,
  CustomerSignUpMutation,
} from "../../graphql/mutations/__generated__/mutations";
import { signinUser } from "../../features/auth/auth-slice";
import { useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { notification } from "antd";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { CUSTOMER_SIGNUP } from "../../graphql/mutations/mutations";
import { SwapSpinner } from "react-spinners-kit";
import { useMemo, useState } from "react";
import { addCustomerToStorefrontCart } from "../../features/cart/cart-slice";
import useCart from "../../hooks/useCart";
import {
  AtSymbolIcon,
  DevicePhoneMobileIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
const ShippingInfo = () => {
  const { shop } = useAppSelector((state) => state?.shop);
  const { userInfo } = useAppSelector((state) => state.auth);
  const { onlineCart } = useCart();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<CustomerSignUpMutationVariables>();
  const [createAccount, { loading }] = useMutation<
    CustomerSignUpMutation,
    CustomerSignUpMutationVariables
  >(CUSTOMER_SIGNUP);
  const personalDetails = useMemo(() => userInfo, [userInfo]);
  const [emailValue, setEmailValue] = useState("");
  const [createNew, setCreateNew] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const onSubmit: SubmitHandler<CustomerSignUpMutationVariables> = (values) => {
    createAccount({
      variables: {
        ...values,
        userId: shop?.ownerId ?? "",
      },
      onCompleted: (res) => {
        if (res?.customerSignUp) {
          console.log(res.customerSignUp);
          dispatch(signinUser(res?.customerSignUp as any));
          dispatch(
            addCustomerToStorefrontCart({
              cart_id: onlineCart?.id as string,
              customer: {
                customer_id: res?.customerSignUp.user?.id as string,
              },
            })
          );
          navigate("/cart/address");
        } else {
          notification.error({
            message: "please contact support",
          });
        }
      },
      onError: (err) => {
        notification.error({
          message: err?.message,
        });
      },
    });
  };

  const handleEmailInputChange = (event: any) => {
    const value = event.target.value;
    setEmailValue(value);

    if (value.trim() !== "") {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  };

  return (
    <div className="space-y-4 w-full">
      <div className="flex items-center w-full lg:top-32 absolute top-24 space-x-2 mb-11">
        <Link to="/cart">
          <h4 className="small-caps font-extralight text-base text-gray-300 hover:text-orange-500">
            Cart
          </h4>
        </Link>
        <ArrowRightIcon className="text-gray-300 h-6" />
        <h4 className="font-bold text-base">Contact Info</h4>
        <ArrowRightIcon className="text-gray-300 h-6" />
        <h4 className="font-extralight text-base text-gray-300">Shipping</h4>
        <ArrowRightIcon className="text-gray-300 h-6" />
        <h1 className="small-caps font-extralight text-base text-gray-300 ">
          Delivery
        </h1>
        <ArrowRightIcon className="text-gray-300 h-6" />
        <h4 className="small-caps font-extralight text-base text-gray-300">
          Payment
        </h4>
      </div>

      <div className="">
        {personalDetails && !createNew ? (
          <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mb-5">
            <div className="flex-auto pl-6 py-3">
              <dt className="text-sm/6 font-semibold text-gray-900">
                Personal details
              </dt>
            </div>
            <div className="px-3 py-3 sm:p-4">
              <dl className="flex flex-wrap">
                <div className="mt-3 flex w-full flex-none gap-x-4 px-3">
                  <dt className="flex-none">
                    <span className="sr-only">name</span>
                    <UserCircleIcon
                      aria-hidden="true"
                      className="h-6 w-5 text-gray-400"
                    />
                  </dt>
                  <dd className="text-sm/6 font-medium text-gray-900">
                    {personalDetails?.name}
                  </dd>
                </div>
                <div className="mt-4 flex w-full flex-none gap-x-4 px-3">
                  <dt className="flex-none">
                    <span className="sr-only">email</span>
                    <AtSymbolIcon
                      aria-hidden="true"
                      className="h-6 w-5 text-gray-400"
                    />
                  </dt>
                  <dd className="text-sm/6 text-gray-500">
                    <time dateTime="2023-01-31">{personalDetails?.email}</time>
                  </dd>
                </div>
                <div className="mt-4 flex w-full flex-none gap-x-4 px-3">
                  <dt className="flex-none">
                    <span className="sr-only">phone</span>
                    <DevicePhoneMobileIcon
                      aria-hidden="true"
                      className="h-6 w-5 text-gray-400"
                    />
                  </dt>
                  <dd className="text-sm/6 text-gray-500">
                    {personalDetails?.phone}
                  </dd>
                </div>
                <span className="text-xs mt-4 py-2 px-1 rounded-md text-gray-500 bg-gray-50">
                  This personal details is from the information provided in your
                  last purchase. You can change the information with the button
                  below
                </span>
              </dl>
            </div>
            <div className="flex items-center justify-between px-4 py-4 sm:px-6">
              <button
                type="button"
                onClick={() => setCreateNew(true)}
                className="relative inline-flex items-center rounded-md bg-pink-600 px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
              >
                Change personal details
              </button>

              <Link
                to={{
                  pathname: `/cart/address`,
                }}
                className="text-sm font-medium text-pink-600 hover:text-pink-500"
                onClick={() => {
                  dispatch(
                    addCustomerToStorefrontCart({
                      cart_id: onlineCart?.id as string,
                      customer: {
                        customer_id: personalDetails?.id as string,
                      },
                    })
                  );
                }}
              >
                Continue
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        ) : (
          <>
            <h4 className="text-sm font-light">
              Fill your details below if you would like to make full payment for
              this order.
            </h4>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-6">
                <label
                  htmlFor="fullname"
                  className="block text-sm font-normal text-gray-500 "
                >
                  Full name*
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <TextInput
                    {...register("input.name", { required: true })}
                    type="text"
                    required
                    id="fullname"
                    className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% font-light  pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Name"
                  />
                  {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )} */}
                </div>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="phone"
                  className="block text-sm font-normal text-gray-500 "
                >
                  Phone number *
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <TextInput
                    {...register("input.phone", { required: true })}
                    type="text"
                    required
                    id="phone"
                    className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                    placeholder=" 0202718168"
                  />
                  {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
            */}
                </div>
              </div>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block text-sm font-normal text-gray-500 "
                >
                  Email
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <TextInput
                    onChangeCapture={handleEmailInputChange}
                    value={emailValue}
                    {...register("input.email", { required: true })}
                    type="email"
                    required
                    id="email"
                    className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                    placeholder=" example@gmail.com"
                  />
                  {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
            */}
                </div>
                {!isFilled ? (
                  <div>
                    <div className="flex flex-row space-x-4 pt-6">
                      <div className="w-4 h-4  mt-1 rounded border-blue-300 border-2"></div>
                      <div className="font-medium">Create an account?</div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row space-x-4 pt-6">
                    <div className="w-4 h-4 bg-blue-300 mt-1 rounded border-blue-300 border-2">
                      <CheckIcon className="text-white " />
                    </div>
                    <div className="font-medium">Create an account?</div>
                  </div>
                )}

                <div className=" font-light text-sm">
                  Your details will be saved and used for other related shops.
                </div>
                <button
                  disabled={loading}
                  type="submit"
                  className="disabled:bg-gray-300 bg-pink-600 disabled:cursor-wait w-full flex justify-center mx-auto lg:float-right mt-4 py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-mainPink hover:bg-darkPink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-600"
                >
                  {loading && <SwapSpinner size={45} color="white" />}
                  {!loading && <p>Submit</p>}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ShippingInfo;
