import { useQuery } from "@apollo/client";
import {
  GetSharedProductQuery,
  GetSharedProductQueryVariables,
} from "../graphql/queries/__generated__/queries";
import { GET_SHARED_PRODUCT } from "../graphql/queries/queries";
import { GetSharedProductRequestDto } from "../graphql/types";

export const useGetSharedProductQuery = (
  variables: GetSharedProductRequestDto
) => {
  const { data, loading, error } = useQuery<
    GetSharedProductQuery,
    GetSharedProductQueryVariables
  >(GET_SHARED_PRODUCT, {
    fetchPolicy: "no-cache",
    variables: {
      input: variables,
    },
  });
  return {
    sharedProduct: data?.getSharedProduct ?? null,
    state: loading
      ? "pending"
      : data && !loading && !error
      ? "success"
      : error
      ? "error"
      : "idle",
  };
};
