import React, { Fragment, ReactNode, useEffect, useRef } from "react";
import usePaystackPayment from "./use-dukePayment";
import { DukePaymentProps } from "./types";
import clsx from "clsx";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addPaymentToStorefrontCart } from "../../features/cart/cart-slice";
// import { Modal } from "@mantine/core";
// import Iframe from "react-iframe";

interface DukePaymentButtonProps extends DukePaymentProps {
  text?: string;
  className?: string;
  children?: ReactNode;
}

const DukePaymentButton = ({
  text,
  className,
  children,
  onSuccess,
  onCancel,
  ...others
}: DukePaymentButtonProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { onlineCart } = useAppSelector((state) => state?.cart);
  const { initializePayment, loading, checkoutLink } = usePaystackPayment(
    others as any
  );

  const init = useRef({ dispatch });

  useEffect(() => {
    const { dispatch } = init.current;
    if (checkoutLink) {
      dispatch(
        addPaymentToStorefrontCart({
          cart_id: onlineCart?.id as string,
          payment: {
            amount: others.amount,
            invoice: checkoutLink,
            payment_id: checkoutLink.slice(checkoutLink.lastIndexOf("/") + 1),
          },
        })
      );
      window.location.href = checkoutLink.concat(
        `&redirect=${window.location.protocol.concat(
          "//" + window.location.host
        )}/confirm-payment?cart_id=${onlineCart?.id}`
      );
    }
  }, [checkoutLink, onlineCart, others.amount]);

  return (
    <Fragment>
      <button
        disabled={loading}
        className={clsx(
          className,
          "disabled:bg-gray-500 disabled:cursor-not-allowed"
        )}
        onClick={(): void => initializePayment()}
      >
        {text || children}
      </button>
    </Fragment>
  );
};

export default DukePaymentButton;

//https://checkout.dukesell.com/GHS/0506339153?data=eyJjdXN0b21lcl9lbWFpbCI6ImViYWlkb283OUBnbWFpbC5jb20iLCJjdXN0b21lcl9maXJzdF9uYW1lIjoiRW1tYW51ZWwiLCJjdXN0b21lcl9sYXN0X25hbWUiOiJCYWlkb28iLCJhbW91bnQiOjF9&redirect=http://fuchsia-baidoo.localhost:3000/cart?success=false
