import { Loader } from "@mantine/core";
import { useGetSharedProductQuery } from "../../hooks/useGetSharedProductQuery";
import { useProduct } from "../../hooks/useProduct";
import { useEffect, useMemo, useRef } from "react";
import { addToCart, clearStorefrontCart } from "../../features/cart/cart-slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Navigate } from "react-router-dom";

type Props = {};

const SingleCheckOutInitialPage = (props: Props) => {
  let urlParams = new URLSearchParams(window.location.search);
  const data = urlParams.get(btoa("KEY").toLowerCase());
  if (!data) {
    // trigger error for invalid url
    alert(
      "Invalid URL, check and try again. Contact shop owner to resend the link"
    );
  }
  const dispatch = useAppDispatch();
  const encodedData = JSON.parse(atob(data as string)) as string[];
  const decodedData = encodedData.reverse().join("");
  const urlData = atob(decodedData);
  urlParams = new URLSearchParams(urlData);

  const id = urlParams.get("product_id");
  const shared_product_id = parseInt(urlParams.get("shared_product_id") ?? "");
  const profit_added = parseFloat(urlParams.get("profit_added") ?? "0");

  const { onlineCart } = useAppSelector((state) => state.cart);

  const { sharedProduct, state } = useGetSharedProductQuery({
    shared_product_id: String(shared_product_id),
  });
  let { product, loading } = useProduct({
    filter: {
      id: {
        eq: id,
      },
    },
  });
  product = useMemo(
    () =>
      product && {
        ...product,
        profit_added,
      },
    [product, profit_added]
  );
  const init = useRef({ dispatch });
  useEffect(() => {
    const { dispatch } = init.current;
    if (product) {
      if (onlineCart) {
        dispatch(clearStorefrontCart({ cart_id: onlineCart.id }))
          .unwrap()
          .then(() => {
            dispatch(addToCart(product));
          });
      } else {
        dispatch(addToCart(product));
      }
    }
  }, [onlineCart, product]);

  if (state === "pending" || loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loader size="xl" />
      </div>
    );
  }

  return (
    <Navigate
      to="/checkout-link"
      replace={true}
      state={{ sharedProduct, product }}
    />
  );
};

export default SingleCheckOutInitialPage;
