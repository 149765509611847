import { useQuery } from "@apollo/client";
import { GET_CUSTOMER } from "../graphql/queries/queries";
import {
  GetCustomerQuery,
  GetCustomerQueryVariables,
} from "../graphql/queries/__generated__/queries";

const useCustomer = (variables?: GetCustomerQueryVariables) => {
  const { data, loading, error, refetch, fetchMore } = useQuery<
    GetCustomerQuery,
    GetCustomerQueryVariables
  >(GET_CUSTOMER, {
    fetchPolicy: "network-only",
    variables,
  });

  return {
    customer: data?.getCustomer,
    loading,
    error,
    refetch,
    fetchMore,
  };
};

export default useCustomer;
