type NestedObject = { [key: string]: any };

export function isEmpty(value: any): boolean {
  if (value === null || value === undefined || value === "") {
    return true;
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }
  return false;
}

export function getNestedValue(obj: NestedObject, path: string): any {
  return path.split(".").reduce((current, key) => {
    return current && current[key] !== undefined ? current[key] : undefined;
  }, obj);
}

export function hasNoValue(obj: NestedObject, path: string): boolean {
  const value = getNestedValue(obj, path);
  return isEmpty(value);
}
