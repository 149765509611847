import React, { useState } from "react";

import {
  CreateCustomerAddressMutation,
  CreateCustomerAddressMutationVariables,
} from "../../../graphql/mutations/__generated__/mutations";

import { useMutation } from "@apollo/client";
import { notification } from "antd";
import { SubmitHandler, useForm } from "react-hook-form";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import TextInput from "../../../components/TextInput/TextInput";
import {
  addCustomerToStorefrontCart,
  setCustomerAddress,
} from "../../../features/cart/cart-slice";
import { CREATE_CUSTOMER_ADDRESS } from "../../../graphql/mutations/mutations";
import { GET_CUSTOMER } from "../../../graphql/queries/queries";

interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddAddress = ({ setShow }: Props) => {
  const { userInfo } = useAppSelector((state) => state?.auth);
  const { onlineCart } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CreateCustomerAddressMutationVariables>();

  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    console.log(ll);
    setAddress(value);
    setValue("input.area", value);
    setValue("input.city", city);
    setValue("input.lat", coordinates.lat);

    setCoordinates(ll);
  };

  function extractCityFromAddress(address: any) {
    const addressParts = address.split(",");
    const city = addressParts[0].trim();
    return city;
  }
  const city = extractCityFromAddress(address);

  const [CreateAddress, { loading }] = useMutation<
    CreateCustomerAddressMutation,
    CreateCustomerAddressMutationVariables
  >(CREATE_CUSTOMER_ADDRESS, {
    refetchQueries: [GET_CUSTOMER],
  });

  const onSubmit: SubmitHandler<CreateCustomerAddressMutationVariables> = (
    values
  ) => {
    console.log(values);
    CreateAddress({
      variables: {
        ...values,
        customerId: userInfo?.id as string,
      },
      onCompleted: (res) => {
        if (res?.createCustomerAddress) {
          dispatch(setCustomerAddress(res?.createCustomerAddress));
          dispatch(
            addCustomerToStorefrontCart({
              cart_id: onlineCart?.id as string,
              customer: {
                ...onlineCart?.customer,
                customer_id: userInfo?.id,
                address_id: res.createCustomerAddress.id,
              },
            })
          );
          setShow(false);
          return;
        } else {
          notification.error({
            message: "please contact support",
          });
          return;
        }
      },
      onError: (err) => {
        notification.error({
          message: err?.message,
        });
      },
    });
  };
  return (
    <div className="">
      <form
        className="flex-1 flex flex-col justify-between h-full "
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col">
          <div className="mb-2 mt-6">
            <label
              htmlFor="Area"
              className="block text-sm font-medium mb-2  lg:text-black "
            >
              Area
            </label>

            <PlacesAutocomplete
              // {...register("input.address.area", { required: true })}
              className="w-1/2"
              value={address}
              onChange={(value: string) => setAddress(value)}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }: {
                getInputProps: any;
                suggestions: any;
                getSuggestionItemProps: any;
                loading: any;
              }) => (
                <div>
                  <TextInput
                    // {...register("input.area", { required: true })}
                    {...getInputProps({
                      placeholder: "Search Area ...",
                      className: "location-search-input",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(
                      (suggestion: {
                        active: any;
                        description:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined;
                      }) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="my-2">
            <label
              htmlFor="area"
              className="block text-sm font-medium lg:text-black "
            >
              Address
            </label>
            <div className="mt-2 relative rounded-md ">
              <TextInput
                {...register("input.city", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                })}
                type="text"
                id="name"
                value={city}
                className="border px-2"
                placeholder="eg. Adenta"
              />
              <ErrorMessage errors={errors} name="input.area" />
            </div>
          </div>
        </div>

        <div className="flex flex-col">
          <div className="my-2">
            <label
              htmlFor="landmark"
              className="block text-sm font-medium lg:text-black "
            >
              Landmark
            </label>
            <div className="mt-2 relative rounded-md ">
              <TextInput
                {...register("input.landmark", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                })}
                type="text"
                id="name"
                className="border px-2"
                placeholder="eg. Anglican church"
              />
              <ErrorMessage errors={errors} name="input.landmark" />
            </div>
          </div>
        </div>

        <div className="flex justify-end py-2 space-x-4">
          <button
            disabled={loading}
            onClick={() => setShow(false)}
            className="disabled:bg-gray-300 disabled:cursor-wait py-3 text-black flex justify-center w-32 text-center  items-center  px-4 border border-transparent rounded-full shadow-sm text-sm font-medium  bg-gray-400 hover:bg-darkPink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="button"
          >
            Cancel
          </button>

          <button
            // variant="primary"
            disabled={loading}
            // loading={loading}
            type="submit"
            className="disabled:bg-gray-300 disabled:cursor-wait flex justify-center w-32 text-center  items-center  px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-darkPink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddAddress;
