import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import plural from "pluralize";

import React, { Fragment, useMemo, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  adjustStorefrontCartItemQuantity,
  decreaseQty,
  increaseQty,
  removeFromCart,
} from "../../features/cart/cart-slice";
import useCart from "../../hooks/useCart";
import { QuantityAdjustmentType } from "../../graphql/types";

type Props = {};

const CartPage = (props: Props) => {
  const { cartLength, cartItems, onlineCart } = useCart();
  const dispatch = useAppDispatch();
  const items = useMemo(() => onlineCart?.items ?? [], [onlineCart]);
  const currency = useMemo(() => onlineCart?.currency ?? "", [onlineCart]);
  const [increaseStatus, setIncreaseStatus] = useState<
    "idle" | "pending" | "success"
  >("idle");
  const [decreaseStatus, setDecreaseStatus] = useState<
    "idle" | "pending" | "success"
  >("idle");

  return (
    <Fragment>
      <div className="flex items-baseline space-x-2">
        <h1 className="font-bold text-3xl">Cart</h1>
        <h3 className="small-caps font-light text-xl text-gray-500">
          {cartLength} {plural("item", cartLength)}
        </h3>
      </div>

      <div className="py-4 space-y-4 lg:mr-6">
        {cartItems?.map((item, idx) => {
          const cartItem = items.find((el) => el?.product_id === item?.id);
          return (
            <div
              key={idx}
              className="flex flex-col w-full border border-gray-300 p-2 rounded-lg md:flex-row items-start"
            >
              <div className="flex flex-row space-x-3">
                <div className="h-32 w-32 lg:h-36 lg:w-36 rounded-md overflow-hidden">
                  <img
                    src={item?.avatar}
                    className="object-cover h-full w-full"
                    alt=""
                  />
                </div>
                <div className="flex flex-col space-y-2 w-full">
                  <span className="font-medium text-sm">{cartItem?.title}</span>
                  {item?.combination_string && (
                    <span className="text-xs font-semibold max-w-max bg-gray-200 rounded-md p-1">
                      {item?.combination_string?.split("&").join(", ")}
                    </span>
                  )}
                  <span className="font-bold text-sm text-gray-600">
                    {currency} {cartItem?.price}
                  </span>

                  <div className="flex justify-between w-full items-center flex-row">
                    <div className="border flex justify-between w-16 lg:w-24 rounded-md border-gray-400 p-1 items-center">
                      <button
                        onClick={() => {
                          setDecreaseStatus("pending");
                          dispatch(decreaseQty(idx));
                          dispatch(
                            adjustStorefrontCartItemQuantity({
                              item_id: cartItem?.id as string,
                              type: QuantityAdjustmentType.Decrease,
                            })
                          )
                            .unwrap()
                            .then(() => setDecreaseStatus("success"))
                            .finally(() => setDecreaseStatus("idle"));
                        }}
                        disabled={increaseStatus === "pending"}
                      >
                        {decreaseStatus === "pending" ? (
                          <div className="h-4 w-4 rounded-full border-t-2 border-l-2 border-gray-500 animate-spin" />
                        ) : (
                          <MinusIcon className="h-4 w-4 hover:cursor-pointer" />
                        )}
                      </button>
                      <span className="text-sm">{cartItem?.quantity}</span>
                      <button
                        onClick={() => {
                          setIncreaseStatus("pending");
                          dispatch(increaseQty(idx));
                          dispatch(
                            adjustStorefrontCartItemQuantity({
                              item_id: cartItem?.id as string,
                              type: QuantityAdjustmentType.Increase,
                            })
                          )
                            .unwrap()
                            .then(() => setIncreaseStatus("success"))
                            .finally(() => setIncreaseStatus("idle"));
                        }}
                        disabled={decreaseStatus === "pending"}
                      >
                        {increaseStatus === "pending" ? (
                          <div className="h-4 w-4 rounded-full border-t-2 border-l-2 border-gray-500 animate-spin" />
                        ) : (
                          <PlusIcon className="h-4 w-4 hover:cursor-pointer" />
                        )}
                      </button>
                    </div>
                    <div className="flex flex-row space-x-2">
                      <TrashIcon className="w-4 h-4 text-red-600" />
                      <button
                        onClick={() => dispatch(removeFromCart(item))}
                        className="text-red-600"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default CartPage;
