import { useEffect } from "react";
import Cookies from "js-cookie";

export const useSetIdentity = () => {
  useEffect(() => {
    const identity = Cookies.get("identity");
    if (!identity) {
      const newIdentity = crypto.randomUUID();
      Cookies.set("identity", newIdentity, { expires: 365 });
    }
  }, []);
};
