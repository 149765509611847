import { Helmet } from "react-helmet-async";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Collections from "../../layouts/StoreFront/components/Collections";
import Products from "../../layouts/StoreFront/components/Products";
import { useEffect, useRef } from "react";
import Cookies from "js-cookie";
import { getStorefrontCart } from "../../features/cart/cart-slice";

type Props = {};

const HomePage = (props: Props) => {
  const dispatch = useAppDispatch();
  const { shop } = useAppSelector((state) => state?.shop);
  const identity = Cookies.get("identity");

  const init = useRef({ dispatch });

  useEffect(() => {
    const { dispatch } = init.current;
    dispatch(
      getStorefrontCart({
        identity: {
          eq: identity,
        },
        status: {
          eq: "ACTIVE",
        },
      })
    );
  }, [identity]);

  return (
    <main>
      <Helmet>
        <title>{shop?.name + "'s shop - Online Storefront"}</title>
        <meta
          property="description"
          content={"Welcome to my shop | " + shop!.name}
        />
        {/*
        <meta property="og:image" itemProp="image"  content="https://d3ug0vbiixnxyq.cloudfront.net/logos/40ctl72xehiurg0pyzukl7-168503038246.jpeg"/> */}
        <meta property="og:type" content="website" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
      </Helmet>
      <Collections />
      {/* Product listing section */}
      <Products />
    </main>
  );
};

export default HomePage;
