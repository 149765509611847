import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";
import { store } from "../app/store";
import { GRAPHQL_API_GATEWAY } from "../constants";

const httpLink = createHttpLink({
  uri: GRAPHQL_API_GATEWAY,
});

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get("access_token");
  const country = store.getState().shop.shop?.country ?? "GH";

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      country,
      source: "store-front",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
