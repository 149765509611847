import { Link, useSearchParams } from "react-router-dom";
import SuccessIcon from "../../assets/success.icon";
import useOrders from "../../hooks/useOrders";
import { Loader } from "@mantine/core";
import { useGetStorefrontCart } from "../../hooks/useGetStorefrontCart";
import { useMemo } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  clearCart,
  updateStorefrontCartStatus,
} from "../../features/cart/cart-slice";
import { StorefrontCartStatusType } from "../../graphql/types";

export default function OrderCompletion() {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const urlSearchData = new URLSearchParams(searchParams);
  const order_id = urlSearchData.get("order_id");
  const cart_id = urlSearchData.get("cart_id");

  const { order, loading } = useOrders({
    filter: {
      id: {
        eq: order_id as string,
      },
    },
  });
  const { cart, fetching } = useGetStorefrontCart({
    filter: {
      id: {
        eq: cart_id as string,
      },
    },
  });
  const products = useMemo(() => cart?.items ?? [], [cart]);

  if (loading || fetching) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loader size="xl" />
      </div>
    );
  }

  return (
    <>
      <main className="relative lg:min-h-full">
        <div className="h-80 overflow-hidden lg:absolute lg:align-middle lg:h-full lg:w-1/2 lg:pr-4 xl:pr-12">
          <SuccessIcon
            width="100%"
            height="100%"
            className="size-full object-cover"
          />
        </div>

        <div>
          <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">
            <div className="lg:col-start-2">
              <h1 className="text-sm font-medium text-indigo-600">
                Payment successful
              </h1>
              <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Thanks for ordering
              </p>
              <p className="mt-2 text-base text-gray-500">
                We appreciate your order, we’re currently processing it. So hang
                tight and we’ll send you confirmation very soon!
              </p>

              <dl className="mt-16 text-sm font-medium">
                <dt className="text-gray-900">Tracking number</dt>
                <dd className="mt-2 text-indigo-600">{order?.tracking_code}</dd>
              </dl>

              <ul className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500">
                {products.map((product) => (
                  <li key={product?.id} className="flex space-x-6 py-6">
                    <img
                      alt={product?.title}
                      src={product?.avatar as string}
                      className="size-24 flex-none rounded-md bg-gray-100 object-cover"
                    />
                    <div className="flex-auto space-y-1">
                      <Link
                        to={{
                          pathname: `/product/${product?.product_id}`,
                        }}
                      >
                        <h3
                          aria-hidden="true"
                          className="text-xs text-gray-900"
                        >
                          {product?.title}
                        </h3>
                      </Link>
                    </div>
                    <p className="flex-none font-medium text-gray-900">
                      {cart?.currency} {product?.price}
                    </p>
                  </li>
                ))}
              </ul>

              <dl className="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">
                <div className="flex justify-between">
                  <dt>Subtotal</dt>
                  <dd className="text-gray-900">
                    {cart?.currency}
                    {cart?.subtotal}
                  </dd>
                </div>

                <div className="flex justify-between">
                  <dt>Shipping</dt>
                  <dd className="text-gray-900">
                    {cart?.currency}
                    {cart?.delivery?.delivery_amount}
                  </dd>
                </div>

                <div className="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">
                  <dt className="text-base">Total</dt>
                  <dd className="text-base">
                    {cart?.currency}
                    {order?.order_total}
                  </dd>
                </div>
              </dl>

              <dl className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4 text-sm text-gray-600">
                <div>
                  <dt className="font-medium text-gray-900">
                    Shipping Address
                  </dt>
                  <dd className="mt-2">
                    <address className="not-italic">
                      <span className="block">{order?.customer?.name}</span>
                      <span className="block">{order?.address?.area}</span>
                      <span className="block">{order?.address?.city}</span>
                    </address>
                  </dd>
                </div>
                <div>
                  <dt className="font-medium text-gray-900">
                    Payment Information
                  </dt>
                  <dd className="mt-2 space-y-2 sm:flex sm:space-x-4 sm:space-y-0">
                    <div className="flex-none">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                        />
                      </svg>

                      <p className="sr-only">{order?.payment?.network}</p>
                    </div>
                    <div className="flex-auto">
                      <p className="text-gray-900">
                        Ending with {order?.payment?.phone?.slice(-4)}
                      </p>
                      <p>{order?.payment_id}</p>
                    </div>
                  </dd>
                </div>
              </dl>

              <div className="mt-16 border-t border-gray-200 py-6 text-right">
                <Link
                  to={{
                    pathname: `/`,
                  }}
                  replace
                  className="text-sm font-medium text-pink-600 hover:text-pink-500"
                  onClick={() => {
                    dispatch(
                      updateStorefrontCartStatus({
                        cart_id: cart_id as string,
                        status: StorefrontCartStatusType.Completed,
                      })
                    );
                    dispatch(clearCart());
                  }}
                >
                  Continue Shopping
                  <span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
