import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import _ from "lodash";
import {
  AddCustomerToStorefrontCartInput,
  AddDeliveryToStorefrontCartInput,
  AddItemToStorefrontCartInput,
  AddPaymentToStorefrontCartInput,
  AdjustStorefrontCartItemQuantityInput,
  ClearStorefrontCartInput,
  CreateStorefrontCartInput,
  Product,
  RemoveItemFromStorefrontCartInput,
  StorefrontCart,
  StorefrontCartFilter,
  UpdateStorefrontCartStatusInput,
} from "../../graphql/types";
import * as R from "ramda";
import { GRAPHQL_API_GATEWAY } from "../../constants";
import {
  ADD_CUSTOMER_TO_STOREFRONT_CART_STRING,
  ADD_DELIVERY_TO_STOREFRONT_CART_STRING,
  ADD_ITEM_TO_CART_STRING,
  ADD_PAYMENT_TO_STOREFRONT_CART_STRING,
  ADJUST_ITEM_QUANTITY_STRING,
  CLEAR_CART_STRING,
  CREATE_CART_STRING,
  GET_STOREFRONT_CART_STRING,
  REMOVE_ITEM_FROM_CART_STRING,
  UPDATE_STOREFRONT_CART_STATUS_STRING,
} from "../../graphql/rest-query-strings";
import { RootState } from "../../app/store";
import Cookies from "js-cookie";

type iProduct = Product & { quantity: number };
interface ICart {
  cartItems: iProduct[];
  cartTotal: number;
  customer_address: any;
  customer_delivery_location: any;
  deliveryPrice: number;
  onlineCart: StorefrontCart | null;
  cartOpStatus: "idle" | "loading" | "succeeded" | "failed";
  cartItemsOpStatus: "idle" | "loading" | "succeeded" | "failed";
  addingToCartState: {
    itemId: string | null;
    status: "idle" | "loading" | "succeeded";
  };
  removingFromCartState: {
    itemId: string | null;
    status: "idle" | "loading" | "succeeded";
  };
}

export const addItemToStorefrontCart = createAsyncThunk(
  "cart/addItemToStorefrontCart",
  async (variables: AddItemToStorefrontCartInput, { getState }) => {
    const state = getState() as RootState;
    const token = Cookies.get("access_token");
    const country = state.shop.shop?.country ?? "GH";
    const response = await fetch(String(GRAPHQL_API_GATEWAY), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
        country,
        source: "store-front",
      },
      body: JSON.stringify({
        query: ADD_ITEM_TO_CART_STRING,
        variables: {
          input: variables,
        },
      }),
    });
    const { data } = await response.json();
    return data.addItemToStoreFrontCart;
  }
);

export const createStorefrontCart = createAsyncThunk(
  "cart/createStorefrontCart",
  async (variables: CreateStorefrontCartInput, { getState }) => {
    const state = getState() as RootState;
    const token = Cookies.get("access_token");
    const country = state.shop.shop?.country ?? "GH";
    const response = await fetch(String(GRAPHQL_API_GATEWAY), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
        country,
        source: "store-front",
      },
      body: JSON.stringify({
        query: CREATE_CART_STRING,
        variables: {
          input: variables,
        },
      }),
    });
    const { data } = await response.json();
    return data.createStorefrontCart;
  }
);

export const removeItemFromStorefrontCart = createAsyncThunk(
  "cart/removeItemFromStorefrontCart",
  async (variables: RemoveItemFromStorefrontCartInput, { getState }) => {
    const state = getState() as RootState;
    const token = Cookies.get("access_token");
    const country = state.shop.shop?.country ?? "GH";
    const response = await fetch(String(GRAPHQL_API_GATEWAY), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
        country,
        source: "store-front",
      },
      body: JSON.stringify({
        query: REMOVE_ITEM_FROM_CART_STRING,
        variables: {
          input: variables,
        },
      }),
    });
    const { data } = await response.json();
    return data.removeItemFromStorefrontCart;
  }
);

export const adjustStorefrontCartItemQuantity = createAsyncThunk(
  "cart/adjustStorefrontCartItemQuantity",
  async (variables: AdjustStorefrontCartItemQuantityInput, { getState }) => {
    const state = getState() as RootState;
    const token = Cookies.get("access_token");
    const country = state.shop.shop?.country ?? "GH";
    const response = await fetch(String(GRAPHQL_API_GATEWAY), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
        country,
        source: "store-front",
      },
      body: JSON.stringify({
        query: ADJUST_ITEM_QUANTITY_STRING,
        variables: {
          input: variables,
        },
      }),
    });
    const { data } = await response.json();
    return data.adjustStorefrontCartItemQuantity;
  }
);

export const updateStorefrontCartStatus = createAsyncThunk(
  "cart/updateStorefrontCartStatus",
  async (variables: UpdateStorefrontCartStatusInput, { getState }) => {
    const state = getState() as RootState;
    const token = Cookies.get("access_token");
    const country = state.shop.shop?.country ?? "GH";
    const response = await fetch(String(GRAPHQL_API_GATEWAY), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
        country,
        source: "store-front",
      },
      body: JSON.stringify({
        query: UPDATE_STOREFRONT_CART_STATUS_STRING,
        variables: {
          input: variables,
        },
      }),
    });
    const { data } = await response.json();
    return data.updateStorefrontCartStatus;
  }
);

export const addPaymentToStorefrontCart = createAsyncThunk(
  "cart/addPaymentToStorefrontCart",
  async (variables: AddPaymentToStorefrontCartInput, { getState }) => {
    const state = getState() as RootState;
    const token = Cookies.get("access_token");
    const country = state.shop.shop?.country ?? "GH";
    const response = await fetch(String(GRAPHQL_API_GATEWAY), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
        country,
        source: "store-front",
      },
      body: JSON.stringify({
        query: ADD_PAYMENT_TO_STOREFRONT_CART_STRING,
        variables: {
          input: variables,
        },
      }),
    });
    const { data } = await response.json();
    return data.addPaymentToStorefrontCart;
  }
);

export const addDeliveryToStorefrontCart = createAsyncThunk(
  "cart/addDeliveryToStorefrontCart",
  async (variables: AddDeliveryToStorefrontCartInput, { getState }) => {
    const state = getState() as RootState;
    const token = Cookies.get("access_token");
    const country = state.shop.shop?.country ?? "GH";
    const response = await fetch(String(GRAPHQL_API_GATEWAY), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
        country,
        source: "store-front",
      },
      body: JSON.stringify({
        query: ADD_DELIVERY_TO_STOREFRONT_CART_STRING,
        variables: {
          input: variables,
        },
      }),
    });
    const { data } = await response.json();
    return data.addDeliveryToStorefrontCart;
  }
);

export const addCustomerToStorefrontCart = createAsyncThunk(
  "cart/addCustomerToStorefrontCart",
  async (variables: AddCustomerToStorefrontCartInput, { getState }) => {
    const state = getState() as RootState;
    const token = Cookies.get("access_token");
    const country = state.shop.shop?.country ?? "GH";
    const response = await fetch(String(GRAPHQL_API_GATEWAY), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
        country,
        source: "store-front",
      },
      body: JSON.stringify({
        query: ADD_CUSTOMER_TO_STOREFRONT_CART_STRING,
        variables: {
          input: variables,
        },
      }),
    });
    const { data } = await response.json();
    return data.addCustomerToStorefrontCart;
  }
);

export const clearStorefrontCart = createAsyncThunk(
  "cart/clearStorefrontCart",
  async (variables: ClearStorefrontCartInput, { getState }) => {
    const state = getState() as RootState;
    const token = Cookies.get("access_token");
    const country = state.shop.shop?.country ?? "GH";
    const response = await fetch(String(GRAPHQL_API_GATEWAY), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
        country,
        source: "store-front",
      },
      body: JSON.stringify({
        query: CLEAR_CART_STRING,
        variables: {
          input: variables,
        },
      }),
    });
    const { data } = await response.json();
    return data.clearStorefrontCart;
  }
);

export const getStorefrontCart = createAsyncThunk(
  "cart/getStorefrontCart",
  async (variables: StorefrontCartFilter, { getState }) => {
    const state = getState() as RootState;
    const token = Cookies.get("access_token");
    const country = state.shop.shop?.country ?? "GH";
    const response = await fetch(String(GRAPHQL_API_GATEWAY), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
        country,
        source: "store-front",
      },
      body: JSON.stringify({
        query: GET_STOREFRONT_CART_STRING,
        variables: {
          filter: variables,
        },
      }),
    });
    const { data } = await response.json();
    return data.getStorefrontCart;
  }
);

const initialState: ICart = {
  cartItems: [],
  cartTotal: 0,
  deliveryPrice: 0,
  customer_address: null,
  customer_delivery_location: null,
  onlineCart: null,
  cartOpStatus: "idle",
  cartItemsOpStatus: "idle",
  addingToCartState: {
    itemId: null,
    status: "idle",
  },
  removingFromCartState: {
    itemId: null,
    status: "idle",
  },
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, { payload }: PayloadAction<any>) => {
      const existingItemIndex = state.cartItems.findIndex(
        (item) => payload.id === item.id
      );

      if (existingItemIndex !== -1) {
        // Item already exists in the cart, update the quantity
        state.cartItems[existingItemIndex].quantity += 1;
      } else {
        // Item doesn't exist, add it to the cart
        state.cartItems = [{ ...payload, quantity: 1 }, ...state.cartItems];
      }

      state.cartTotal = state.cartItems.reduce<number>((total, cur) => {
        return (
          Number(total) +
          _.multiply(cur?.quantity, _.sum([cur?.price, cur?.profit_added]))
        );
      }, 0);
    },
    setAddingToCartState: (
      state,
      { payload }: PayloadAction<ICart["addingToCartState"]>
    ) => {
      state.addingToCartState = {
        itemId: payload.itemId,
        status: payload.status,
      };
    },
    removeFromCart: (state, { payload }: PayloadAction<any>) => {
      const newCartItems = state.cartItems.filter(
        (item) => item.id !== payload.id
      );
      state.cartItems = newCartItems;
      state.cartTotal =
        state.cartTotal - _.sum([payload?.price, payload?.profit_added]);
    },
    setRemovingFromCartState: (
      state,
      { payload }: PayloadAction<ICart["removingFromCartState"]>
    ) => {
      state.removingFromCartState = {
        itemId: payload.itemId,
        status: payload.status,
      };
    },
    clearCart: (state) => {
      state.cartItems = [];
      state.cartTotal = 0;
      state.customer_delivery_location = null;
      state.customer_address = null;
      state.deliveryPrice = 0;
      state.onlineCart = null;
    },
    setCustomerAddress: (state, { payload }: PayloadAction<any>) => {
      state.customer_address = payload;
    },
    setCustomerDeliveryLocation: (state, { payload }: PayloadAction<any>) => {
      state.customer_delivery_location = payload;
    },
    setdeliveryPrice: (state, { payload }: PayloadAction<any>) => {
      state.deliveryPrice = payload;
    },
    increaseQty: (state, action: PayloadAction<number>) => {
      state.cartItems = R.adjust(
        action.payload,
        (el) => ({
          ...el,
          quantity:
            parseInt(el?.qty?.toString() ?? Infinity.toString()) > el?.quantity
              ? el.quantity + 1
              : el.quantity,
        }),
        state.cartItems
      );
    },
    decreaseQty: (state, action: PayloadAction<number>) => {
      state.cartItems = R.adjust(
        action.payload,
        (el) => ({
          ...el,
          quantity: el.quantity < 2 ? el.quantity : el.quantity - 1,
        }),
        state.cartItems
      );
    },
    setOnlineCart: (state, action: PayloadAction<StorefrontCart>) => {
      state.onlineCart = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(createStorefrontCart.pending, (state) => {
        state.cartOpStatus = "loading";
      })
      .addCase(addItemToStorefrontCart.fulfilled, (state, action) => {
        state.cartOpStatus = "succeeded";
        state.onlineCart = { ...state.onlineCart, ...action.payload };
      })
      .addCase(createStorefrontCart.fulfilled, (state, action) => {
        state.cartOpStatus = "succeeded";
        state.onlineCart = action.payload;
      })
      .addCase(getStorefrontCart.fulfilled, (state, action) => {
        state.onlineCart = action.payload;
      })
      .addCase(removeItemFromStorefrontCart.fulfilled, (state, action) => {
        state.cartItemsOpStatus = "succeeded";
        state.onlineCart = { ...state.onlineCart, ...action.payload };
      })
      .addCase(removeItemFromStorefrontCart.pending, (state) => {
        state.cartItemsOpStatus = "loading";
      })
      .addCase(adjustStorefrontCartItemQuantity.fulfilled, (state, action) => {
        state.cartItemsOpStatus = "succeeded";
        state.onlineCart = { ...state.onlineCart, ...action.payload };
      })

      .addCase(addPaymentToStorefrontCart.fulfilled, (state, action) => {
        state.onlineCart = { ...state.onlineCart, ...action.payload };
      })
      .addCase(addDeliveryToStorefrontCart.fulfilled, (state, action) => {
        state.onlineCart = { ...state.onlineCart, ...action.payload };
      })
      .addCase(addCustomerToStorefrontCart.fulfilled, (state, action) => {
        state.onlineCart = { ...state.onlineCart, ...action.payload };
      })
      .addCase(clearStorefrontCart.fulfilled, (state, action) => {
        state.onlineCart = null;
      });
  },
});

export const {
  addToCart,
  removeFromCart,
  setCustomerAddress,
  setCustomerDeliveryLocation,
  setdeliveryPrice,
  clearCart,
  decreaseQty,
  increaseQty,
  setAddingToCartState,
  setRemovingFromCartState,
} = cartSlice.actions;

export default cartSlice.reducer;
