import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";

type Props = {
  product: any;
};

const DigitalProductCard = ({ product }: Props) => {
  const navigate = useNavigate();
  const handleCheckout = () => {
    navigate(`/storefront-digital-order/${product?.id}`);
  };

  function limitTitle(sentence: string, maxWidth: number) {
    if (sentence.length <= maxWidth) {
      return sentence;
    } else {
      return sentence.slice(0, maxWidth - 3) + "...";
    }
  }
  const originalSentence = product?.title;
  const maxWidth = 32;
  const limitedTitle = limitTitle(originalSentence, maxWidth);

  return (
    <div className="flex flex-col  lg:w-72  overflow-hidden rounded-lg  mb-8  bg-white">
      <div className="h-56 w-full overflow-hidden bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
        <Link
          to={{
            pathname: `/digital-product/${product?.id}`,
          }}
        >
          <img
            src={product?.avatar}
            alt={product?.title}
            className="h-full object-cover w-full"
          />
        </Link>
      </div>
      {/* <div className="flex flex-1 flex-col space-y-2 p-4"> */}
      <div className="flex mt-2 sm:-mt-6 flex-1 space-y-1  ">
        <div className="flex flex-col space-y-1 mx-2 lg:mx-4">
          <div>
            <Link
              to={{
                pathname: `/digital-product/${product?.id}`,
              }}
            >
              <h3 aria-hidden="true" className="text-xs text-gray-900">
                {limitedTitle}
              </h3>
            </Link>
          </div>

          <div>
            <p className=" font-bold  text-gray-900  text-xs">
              {product?.currency}{" "}
              {_.sum([product?.price, product?.profit_added])}
            </p>
          </div>

          <div>
            <button
              onClick={handleCheckout}
              className="bg-[#FFFFFF] border border-gray-400 rounded-md text-center text-sm text-black p-1 mb-3  w-40 sm:w-64"
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalProductCard;
