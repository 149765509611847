import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { COUNTRY_KEY } from "../../constants";

export type NotificationPayload = {
  id?: string;
  type: "success" | "error" | "warning" | "info" | "custom";
  title?: string;
  message?: string;
  timeout?: number; // time to dismiss notification
  dismissible: boolean;
  content?: React.ReactNode;
};
export interface AppState {
  country: string;
  showNotification: boolean;
  notifications: NotificationPayload[];
}

const initialState: AppState = {
  country: "",
  showNotification: false,
  notifications: [],
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setCountry: (state, { payload }: PayloadAction<string>) => {
      localStorage.setItem(COUNTRY_KEY, payload === "Nigeria" ? "NG" : "GH");
      state.country = payload;
    },
    displayNotification: (
      state,
      {
        payload,
      }: PayloadAction<{
        showNotification: boolean;
        notification: NotificationPayload;
      }>
    ) => {
      state.showNotification = payload.showNotification;

      state.notifications = state.notifications
        ? [...state.notifications, payload.notification]
        : [payload.notification];
    },
    dismissNotification: (
      state,
      { payload }: PayloadAction<{ id: string }>
    ) => {
      state.notifications = state.notifications.filter(
        (n) => n.id !== payload.id
      );
      if (state.notifications.length === 0) {
        state.showNotification = false;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCountry, dismissNotification, displayNotification } =
  appSlice.actions;

export default appSlice.reducer;
