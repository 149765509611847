import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  createListenerMiddleware,
} from "@reduxjs/toolkit";
import appSlice, { displayNotification } from "../features/app/app-slice";
import authSlice from "../features/auth/auth-slice";
import cartSlice, {
  addItemToStorefrontCart,
  addToCart,
  createStorefrontCart,
  getStorefrontCart,
  removeFromCart,
  removeItemFromStorefrontCart,
  setAddingToCartState,
  setRemovingFromCartState,
  updateStorefrontCartStatus,
} from "../features/cart/cart-slice";
import productSlice from "../features/product/product-slice";
import shopSlice from "../features/shop/shop-slice";
import storage from "localforage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { StorefrontCartItemInput } from "../graphql/types";
import Cookies from "js-cookie";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducers = combineReducers({
  cart: cartSlice,
  app: appSlice,
  shop: shopSlice,
  product: productSlice,
  auth: authSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);
const listenerMiddleware = createListenerMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(listenerMiddleware.middleware),
});

listenerMiddleware.startListening({
  actionCreator: addToCart,
  effect: async (action, { dispatch, getState }) => {
    const state = getState() as RootState;
    const identity = Cookies.get("identity");
    const { payload } = action;
    const item: StorefrontCartItemInput = {
      sku: payload.sku,
      quantity: 1,
      price: parseFloat(
        Number(payload.price + payload.profit_added).toFixed(2)
      ),
      product_price: payload.price,
      title: payload.title,
      product_id: payload.id,
      reseller_profit: payload.profit_added,
      avatar: payload.avatar,
    };
    if (state.cart.onlineCart) {
      dispatch(
        setAddingToCartState({ itemId: item.product_id, status: "loading" })
      );
      dispatch(
        addItemToStorefrontCart({
          cart_id: state.cart.onlineCart.id,
          item,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(
            setAddingToCartState({
              itemId: item.product_id,
              status: "succeeded",
            })
          );
          dispatch(
            displayNotification({
              showNotification: true,
              notification: {
                dismissible: true,
                type: "info",
                message: "Item added to cart",
              },
            })
          );
        })
        .finally(() =>
          dispatch(setAddingToCartState({ itemId: null, status: "idle" }))
        );
    } else {
      dispatch(
        setAddingToCartState({ itemId: item.product_id, status: "loading" })
      );
      dispatch(
        createStorefrontCart({
          shop_id: state.shop.shop?.id as string,
          identity,
          items: [item],
        })
      )
        .unwrap()
        .then(() => {
          dispatch(
            setAddingToCartState({
              itemId: item.product_id,
              status: "succeeded",
            })
          );
          dispatch(
            displayNotification({
              showNotification: true,
              notification: {
                dismissible: true,
                type: "info",
                message: "Item added to cart",
              },
            })
          );
        })
        .finally(() =>
          dispatch(setAddingToCartState({ itemId: null, status: "idle" }))
        );
    }
  },
});

listenerMiddleware.startListening({
  actionCreator: updateStorefrontCartStatus.fulfilled,
  effect: async (action, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { payload } = action;
    if (payload) {
      dispatch(
        getStorefrontCart({
          id: {
            eq: state.cart.onlineCart?.id,
          },
        })
      );
    }
  },
});

listenerMiddleware.startListening({
  actionCreator: removeFromCart,
  effect: async (action, { dispatch, getState }) => {
    const state = getState() as RootState;
    const { payload } = action;
    const item = state.cart.onlineCart?.items?.find(
      (el) => el?.product_id === payload?.id
    );
    dispatch(
      setRemovingFromCartState({
        itemId: item?.product_id as string,
        status: "loading",
      })
    );
    dispatch(
      removeItemFromStorefrontCart({
        cart_id: state.cart.onlineCart?.id as string,
        item: item as StorefrontCartItemInput,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          setRemovingFromCartState({
            itemId: item?.product_id as string,
            status: "succeeded",
          })
        );
        dispatch(
          displayNotification({
            showNotification: true,
            notification: {
              dismissible: true,
              type: "info",
              message: "Item removed from cart",
            },
          })
        );
      })
      .finally(() =>
        dispatch(setRemovingFromCartState({ itemId: null, status: "idle" }))
      );
  },
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
