import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
// import App from "./App";
import { ApolloProvider } from "@apollo/client";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { StrictMode } from "react";
import client from "./graphql/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Root from "./Root";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <StrictMode>
    <MantineProvider withNormalizeCSS withGlobalStyles>
      <NotificationsProvider>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <Root />
          </Provider>
        </ApolloProvider>
      </NotificationsProvider>
    </MantineProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
