import { gql } from "@apollo/client";

export const GET_DIGITAL_ORDER = gql`
  query GetDigitalOrder($filter: DigitalOrderFilter!) {
    getDigitalOrder(filter: $filter) {
      digitalDetails
      createdAt
      customer {
        name
        email
        phone
      }
      paymentStatus
      deliveryMode
      customerId
      price
      productId
      profitAdded
      reseller {
        id
      }
      status
    }
  }
`;
export const GET_SHOP = gql`
  query GetShop($filter: ShopFilter!) {
    getShop(filter: $filter) {
      id
      name
      description
      contactEmail
      email
      currency
      slug
      primaryDomain
      sokoWorldDomain
      customDomains
      hasBeenSetup
      isActive
      shopTags
      createdAt
      updatedAt
      avatar
      country
      coverImage
      deliveryOption
      deliveryAgent
      paymentOptions
      url
      shortUrl
      phone
      ownerId
      supplierId
      shopId
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetShopProducts($shopId: ID!, $filter: ShopProductsFilter) {
    getShopProducts(shop_id: $shopId, filter: $filter) {
      id
      title
      sku
      limited_stock
      qty
      digitalDetails
      reviews {
        id
        rating
        comment
        user_id
      }
      reviewBreakDown {
        totalReviews
        breakDown {
          poor
          average
          good
          veryGood
          excellent
        }
      }
      overalReview
      price
      profit_added
      currency
      country
      description
      combination_string
      published
      inhouse
      attended_to
      avatar
      cost_price
      verified
      weight
      tags
      uuid
      likesCount
      savesCount
      sharesCount
      supplier_id
      instantDelivery
    }
  }
`;

export const GET_PRODUCT = gql`
  query GetProduct($filter: productFilter) {
    getProduct(filter: $filter) {
      id
      title
      sku
      limited_stock
      qty
      digitalDetails
      reviews {
        id
        rating
        comment
        user_id
      }
      reviewBreakDown {
        totalReviews
        breakDown {
          poor
          average
          good
          veryGood
          excellent
        }
      }
      overalReview
      price
      profit_added
      currency
      country
      description
      combination_string
      published
      inhouse
      attended_to
      verified
      cost_price
      weight
      avatar
      tags
      shop_id
      resell
      uuid
      likesCount
      savesCount
      sharesCount
      supplier_id
      instantDelivery
      media {
        id
        avatar
        product_id
      }
    }
  }
`;

// export const GET_PRODUCTS = gql`
//   query GetProducts($filter: productFilter) {
//     getProducts(filter: $filter) {
//       id
//       title
//       productType
//       sku
//       avatar
//       limited_stock
//       qty
//       price
//       currency
//       description
//       published
//       sharesCount
//       savesCount
//       likesCount
//       profit_added
//        digitalDetails
//        reviews {
//       id
//       rating
//       comment
//       user_id
//     }
//     reviewBreakDown {
//       totalReviews
//       breakDown {
//         poor
//         average
//         good
//         veryGood
//         excellent
//       }
//     }
//       instantDelivery
//       supplier {
//         id
//         name
//         email
//         phone

//         has_verified_phonenumber
//         has_verified_email
//         setup_complete
//         createdAt
//         deletedAt
//       }
//       main_stock
//       transit_stock
//       country
//       combination_string
//       inhouse
//       attended_to
//       verified
//       cost_price
//       weight
//       tags
//       shop_id
//       resell
//       uuid
//       supplier_id
//       created_at
//       media {
//         avatar
//       }
//       discount {
//         id
//         type
//         valid_country
//         code
//         percentage
//         amount
//         description
//         is_draft
//         start_date
//         expiry_date
//         redemptions_limit
//         product_condition
//         products
//         fixed_amount_allocation
//         created_at
//         updated_at
//         created_by
//         user_id
//         has_expired
//         number_of_times_used
//       }
//     }
//   }
// `;

export const GET_CUSTOMER = gql`
  query GetCustomer($filter: CustomerFilter) {
    getCustomer(filter: $filter) {
      name
      address {
        country
        city
        area
        landmark
        id
      }
      id
      email
      phone
      reseller {
        name
        phone
      }
    }
  }
`;

export const GET_ORDERS = gql`
  query GetOrders(
    $filter: OrderFilter
    $pagination: Pagination
    $sort: OrderSort
  ) {
    getOrders(filter: $filter, pagination: $pagination, sort: $sort) {
      id
      orderNumber
      quantity
      profitAdded
      status
      price
      sku
      createdAt
      updatedAt
      product {
        sku
        avatar
        currency
        cost_price
        inhouse
        price
        profit_added
        title
      }
      comments {
        id
        message
        title
        staff_name
        createdAt
        updatedAt
      }
      history {
        id
        status
        ops_personel
        created_at
        updated_at
      }
      order {
        id
        customer_id
        supplier_id
        customer_address_id
        user_id
        order_total
        cart_number
        delivery {
          id
          location
          price
          country
          remarks
          isPayOnline
        }
        payment_status
        discount
        free_delivery
        payment_id
        payment_type
        delivery_instructions
        source
        country
        createdAt
        updatedAt
        customer {
          id
          name
          email
          phone
        }
        supplier {
          id
          name
          email
          phone
          country
          has_verified_phonenumber
          has_verified_email
          setup_complete
          generic_name
          createdAt
          deletedAt
        }
        address {
          country
          city
          area
          landmark
          lat
          lng
          id
        }
        reseller {
          id
          email
          name
          country
          phone
          verified
          first_order_created
          user_type
          username
          google_id
          facebook_id
          twitter_id
        }
      }
    }
  }
`;

export const GET_SHOP_COLLECTIONS = gql`
  query GetShopCollections(
    $filter: ShopCollectionFilter
    $pagination: Pagination
  ) {
    getShopCollections(filter: $filter, pagination: $pagination) {
      banner
      id
      is_active
      name
    }
  }
`;
export const GET_SHOP_COLLECTION = gql`
  query GetShopCollection($filter: ShopCollectionFilter!) {
    getShopCollection(filter: $filter) {
      banner
      id
      is_active
      name
    }
  }
`;

export const GET_DELIVERY_LOCATION = gql`
  query DeliveryLocations($filter: DeliveryFilter) {
    deliveryLocations(filter: $filter) {
      id
      isPayOnline
      hasInstantDelivery
      instantDeliveryPrice
      location
      price
      remarks
    }
  }
`;

export const GET_PICKUP_LOCATIONS = gql`
  query GetPickUpLocations($filter: DeliveryFilter, $pagination: Pagination) {
    getPickUpLocations(filter: $filter, pagination: $pagination) {
      id
      location
      country
      google_location
      longitude
      latitude
      remarks
    }
  }
`;

export const GET_USER = gql`
  query User($userId: ID!) {
    user(id: $userId) {
      email
      name
      id
      phone
    }
  }
`;
export const GET_REVIEWS = gql`
  query GetReviews($filter: ReviewFilter, $pagination: Pagination) {
    getReviews(filter: $filter, pagination: $pagination) {
      comment
      id
      rating
      reseller {
        name
      }
      user_id
    }
  }
`;
export const GET_REVIEWLENGTH = gql`
  query Query($filter: ReviewFilter!) {
    getReviewsLength(filter: $filter)
  }
`;

export const GET_SHAREDPRODUCTS = gql`
  query GetSharedProducts($filter: SharedProductFilter) {
    getSharedProducts(filter: $filter) {
      created_at
      id
      long_url
      number_of_times_sold
      product_cost_price
      product_id
      profit_added_by_reseller
      reseller {
        id
        shop {
          id
        }
      }
      self_checkout_link
      shared_by
      short_url
      total_accumulated_profit
      total_accumulated_sales
      total_selling_price
      updated_at
    }
  }
`;

export const GET_SHARED_PRODUCT = gql`
  query GetSharedProduct($input: GetSharedProductRequestDto!) {
    getSharedProduct(input: $input) {
      id
      number_of_times_sold
      product_cost_price
      product_id
      profit_added_by_reseller
      reseller {
        id
        shop {
          id
        }
      }
      self_checkout_link
      shared_by
      short_url
      total_selling_price
      product {
        avatar
        combination_string
        country
        currency
        description
        inhouse
        instantDelivery
        price
        productType
        title
      }
    }
  }
`;

export const GET_CARTS = gql`
  query GetCarts($filter: CartFilter) {
    getCarts(filter: $filter) {
      cart_number
      customer_paid
      order_total
      tracking_code
      address {
        area
        city
        landmark
      }
      createdAt
      customer {
        name
        phone
        email
      }
      delivery {
        location
        price
      }
      delivery_amount
      payment_status
      payment_type
    }
  }
`;

export const GET_CART = gql`
  query GetCart($filter: CartFilter) {
    getCart(filter: $filter) {
      delivery_id
      delivery {
        country
        id
        isPayOnline
        location
        price
        remarks
      }
      id
      customer {
        name
        phone
        email
      }
      payment {
        amount
        network
        phone
      }
      customer_id
      customer_address_id
      user_id
      order_total
      delivery_amount
      customer_paid
      cart_number
      orderItems {
        comments {
          createdAt
          id
          message
          staff_name
          title
          updatedAt
        }
        createdAt
        id
        orderNumber
        order_id
        price
        profitAdded
        product {
          avatar
          description
          title
        }
        quantity
        sku
        status
        history {
          created_at
          id
          ops_personel
          status
          updated_at
        }
      }
      payment_status
      discount
      free_delivery
      payment_id
      payment_type
      tracking_code
      delivery_instructions
      country
      createdAt
      updatedAt
      address {
        area
        city
      }
    }
  }
`;

export const GET_PAYMENT_REFERENCE = gql`
  query GetPaymentByReference($data: GetPaymentByReferenceRequestDto!) {
    getPaymentByReference(data: $data) {
      payment {
        id
        amount
        email
        message
        network
        phone
        psp
        reasonForPayment
        reference
        status
        currency
      }
    }
  }
`;

export const GET_STOREFRONT_CART = gql`
  query GetStorefrontCart($filter: StorefrontCartFilter) {
    getStorefrontCart(filter: $filter) {
      id
      identity
      items {
        id
        price
        product_id
        title
        product_price
        quantity
        sku
        cart_id
        reseller_profit
        avatar
      }
      customer {
        address_id
        customer_id
      }
      delivery {
        delivery_amount
        delivery_id
        delivery_type
        instantDelivery
      }
      payment {
        amount
        discount
        invoice
        payment_id
        payment_status
        payment_type
      }
      shop_id
      subtotal
      total
      status
      country
      currency
      created_at
    }
  }
`;
