import { useQuery } from "@apollo/client";
import {
  GetPaymentByReferenceQuery,
  GetPaymentByReferenceQueryVariables,
} from "../graphql/queries/__generated__/queries";
import { GET_PAYMENT_REFERENCE } from "../graphql/queries/queries";

export const useVerifyPaymentWithReference = (reference: string) => {
  const { data, loading, error, refetch } = useQuery<
    GetPaymentByReferenceQuery,
    GetPaymentByReferenceQueryVariables
  >(GET_PAYMENT_REFERENCE, {
    variables: {
      data: {
        reference,
      },
    },
  });
  return {
    data: data?.getPaymentByReference?.payment ?? null,
    loading,
    error,
    refetch,
  };
};
