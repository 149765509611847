import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Tabs } from "@mantine/core";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import {
  addDeliveryToStorefrontCart,
  setCustomerDeliveryLocation,
  setdeliveryPrice,
} from "../../features/cart/cart-slice";
import useCart from "../../hooks/useCart";
import useDeliveryLocations from "../../hooks/useDeliveryLocations";

const DeliveryPage = () => {
  const [activeTab, setActiveTab] = useState<string | null>(
    "standard delivery"
  );
  const [selectedDeliveryType, setSelectedDeliveryType] =
    useState("standard delivery");
  const dispatch = useAppDispatch();
  const { customer_delivery_location, cartItems, onlineCart } = useCart();
  const hasInstantDelivery = cartItems.some((item) => item.instantDelivery);
  const { deliveryLocations } = useDeliveryLocations();
  const { deliveryLocations: instantdeliverylocations } = useDeliveryLocations({
    filter: {
      hasInstantDelivery: {
        eq: true,
      },
    },
  });

  return (
    <>
      <div className="space-y-4 w-full">
        <div className="flex items-center space-x-2 mb-11">
          <h4 className="small-caps font-extralight text-xl text-gray-300">
            Cart
          </h4>
          <ArrowRightIcon className="text-gray-300 h-6" />
          <h4 className="small-caps font-extralight text-xl text-gray-300">
            Contact Info
          </h4>
          <ArrowRightIcon className="text-gray-300 h-6" />
          <Link to="/cart/address">
            <h4 className="font-extralight text-xl text-gray-300 hover:text-orange-500">
              Shipping
            </h4>
          </Link>
          <ArrowRightIcon className="text-gray-300 h-6" />
          <h4 className="small-caps font-bold text-xl ">Delivery</h4>
          <ArrowRightIcon className="text-gray-300 h-6" />
          <h4 className="small-caps font-extralight text-xl text-gray-300">
            Payment
          </h4>
        </div>
        <Tabs
          value={activeTab}
          onTabChange={(tab) => {
            setActiveTab(tab);
            setSelectedDeliveryType(
              tab === "standard delivery"
                ? "standard delivery"
                : "express delivery"
            );
          }}
        >
          <Tabs.List>
            <Tabs.Tab value="standard delivery">Standard Delivery</Tabs.Tab>
            {hasInstantDelivery && (
              <Tabs.Tab value="express delivery">Express Delivery</Tabs.Tab>
            )}
          </Tabs.List>

          <Tabs.Panel value="standard delivery">
            <div className="flex flex-col my-4 w-84">
              <select
                name="standard_delivery"
                id=""
                onChange={(e) => {
                  const location = JSON.parse(e.target.value);
                  const location_price = location?.price;
                  dispatch(setdeliveryPrice(location_price));
                  dispatch(setCustomerDeliveryLocation(location));
                  dispatch(
                    addDeliveryToStorefrontCart({
                      cart_id: onlineCart?.id as string,
                      delivery: {
                        delivery_amount: location_price,
                        delivery_id: location?.id,
                        delivery_type: "DELIVERY",
                        instantDelivery: false,
                      },
                    })
                  );
                }}
                className=""
              >
                <option value="">Select Delivery Zone</option>
                {deliveryLocations.map((location, idx) => (
                  <option
                    key={idx}
                    value={JSON.stringify(location)}
                    className="flex w-64 whitespace-nowrap  overflow-hidden text-ellipsis"
                  >
                    {location?.location} - {location?.price}
                  </option>
                ))}
              </select>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="express delivery">
            <div className="flex flex-col my-4 w-84">
              <select
                name="instant_delivery"
                id=""
                onChange={(e) => {
                  const location = JSON.parse(e.currentTarget.value);
                  const location_price = location?.price;
                  dispatch(setdeliveryPrice(location_price));
                  dispatch(setCustomerDeliveryLocation(location));
                  dispatch(
                    addDeliveryToStorefrontCart({
                      cart_id: onlineCart?.id as string,
                      delivery: {
                        delivery_amount: location_price,
                        delivery_id: location?.id,
                        delivery_type: "DELIVERY",
                        instantDelivery: true,
                      },
                    })
                  );
                }}
                className=""
              >
                <option value="">Select Delivery Zone</option>
                {instantdeliverylocations.map((location, idx) => (
                  <option
                    key={idx}
                    value={JSON.stringify(location)}
                    className="flex w-64 whitespace-nowrap  overflow-hidden text-ellipsis"
                  >
                    {location?.location} - {location?.instantDeliveryPrice}
                  </option>
                ))}
              </select>
            </div>
          </Tabs.Panel>
        </Tabs>

        <div className="bg-gray-400 p-3 rounded-md flex flex-col my-4">
          <span className="text-white">
            {customer_delivery_location?.location}
          </span>
          <span className="text-white">
            Price :{" "}
            {selectedDeliveryType === "express delivery"
              ? customer_delivery_location?.instantDeliveryPrice
              : customer_delivery_location?.price}
          </span>
          <span>
            {selectedDeliveryType === "express delivery"
              ? "Express delivery items are delivered within 24 hours."
              : "Standard delivery items are delivered within 3-4 working days after order is placed."}
          </span>
        </div>
      </div>
      <hr />
    </>
  );
};

export default DeliveryPage;
