import { useMutation } from "@apollo/client";
import { Tab } from "@headlessui/react";
import { CheckIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Tabs } from "@mantine/core";
import { notification } from "antd";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import DukePaymentButton from "../../components/duke-payment/duke-payment";
import Show from "../../components/Show";
import TextInput from "../../components/TextInput/TextInput";
import { signinUser } from "../../features/auth/auth-slice";
import {
  addCustomerToStorefrontCart,
  addDeliveryToStorefrontCart,
  adjustStorefrontCartItemQuantity,
  decreaseQty,
  increaseQty,
  setCustomerDeliveryLocation,
} from "../../features/cart/cart-slice";
import {
  CustomerSignUpMutation,
  CustomerSignUpMutationVariables,
} from "../../graphql/mutations/__generated__/mutations";
import { CUSTOMER_SIGNUP } from "../../graphql/mutations/mutations";
import { QuantityAdjustmentType } from "../../graphql/types";
import useCart from "../../hooks/useCart";
import useDeliveryLocations from "../../hooks/useDeliveryLocations";
import { useNotification } from "../../hooks/useNotification";
import { hasNoValue } from "../../utils";

interface FormData {
  firstName: string;
  lastName: string;
}

const SingleCheckoutPage: React.FunctionComponent = () => {
  let location = useLocation();
  const { state } = location;
  const { sharedProduct, product } = state;
  const [errorNotification, setErrorNotification] = useState({
    show: false,
    message: "",
  });
  const { shop } = useAppSelector((state) => state?.shop);
  const { userInfo } = useAppSelector((state) => state.auth);
  const { onlineCart } = useAppSelector((state) => state.cart);
  const { customer_delivery_location } = useCart();
  const [activeTab, setActiveTab] = useState<string | null>("delivery");
  const [increaseStatus, setIncreaseStatus] = useState<
    "idle" | "pending" | "success"
  >("idle");
  const [decreaseStatus, setDecreaseStatus] = useState<
    "idle" | "pending" | "success"
  >("idle");
  const { deliveryLocations } = useDeliveryLocations();
  const { notify } = useNotification();

  const dispatch = useAppDispatch();
  const { register, handleSubmit, setValue, watch } = useForm<
    CustomerSignUpMutationVariables,
    FormData
  >();
  const [address, setAddress] = useState("");
  const formValues = watch();
  const isFilled = useMemo(() => {
    if (!formValues) return false;
    return (
      !hasNoValue(formValues, "input.address.area") &&
      !hasNoValue(formValues, "input.address.city") &&
      !hasNoValue(formValues, "input.name") &&
      !hasNoValue(formValues, "input.email") &&
      !hasNoValue(formValues, "input.phone") &&
      !hasNoValue(formValues, "input.address")
    );
  }, [formValues]);
  const sellingPrice = useMemo(
    () => sharedProduct?.total_selling_price ?? 0.0,
    [sharedProduct?.total_selling_price]
  );
  const formBtnRef = useRef<HTMLButtonElement | null>(null);
  function extractCityFromAddress(address: any) {
    const addressParts = address.split(",");
    const city = addressParts[0].trim();
    return city;
  }
  const city = useMemo(() => extractCityFromAddress(address), [address]);
  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    console.log(ll);
    setAddress(value);
    setValue("input.address.area", value);
    setValue("input.address.city", city);
    setValue("input.address.lat", String(ll.lat));
    setValue("input.address.lng", String(ll.lng));
  };

  const [createAccount] = useMutation<
    CustomerSignUpMutation,
    CustomerSignUpMutationVariables
  >(CUSTOMER_SIGNUP);
  const init = useRef({ dispatch, createAccount });
  const onSubmit: SubmitHandler<CustomerSignUpMutationVariables> = useCallback(
    (values) => {
      const { createAccount, dispatch } = init.current;
      createAccount({
        variables: {
          ...values,
          userId: shop?.ownerId ?? "",
        },
        onCompleted: (res) => {
          // setShowSignIn(false);
          if (res?.customerSignUp) {
            const addedAddress = res?.customerSignUp?.user?.address![0];
            dispatch(signinUser(res?.customerSignUp as any));
            dispatch(
              addCustomerToStorefrontCart({
                cart_id: onlineCart?.id as string,
                customer: {
                  ...onlineCart?.customer,
                  customer_id: res?.customerSignUp?.user?.id,
                  address_id: addedAddress?.id,
                },
              })
            );
          } else {
            notification.error({
              message: "please contact support",
            });
          }
        },
        onError: (err: { message: any }) => {
          notification.error({
            message: err?.message,
          });
        },
      });
    },
    [onlineCart?.customer, onlineCart?.id, shop?.ownerId]
  );

  useEffect(() => {
    if (isFilled && onlineCart?.id && formBtnRef.current) {
      formBtnRef.current.click();
    }
  }, [isFilled, onlineCart?.id]);

  const startDate = moment().add(4, "day").startOf("day");
  const endDate = moment().add(5, "day").startOf("day");
  const startDateArr = startDate.format("MMM Do YYY").split(" ");
  const endDateArr = endDate.format("MMM Do YY").split(" ");

  const handleClick = useCallback(() => {
    setErrorNotification({
      show: true,
      message: "Kindly fill the form and select a delivery location.",
    });
  }, []);

  const disableButton = useMemo(
    () =>
      !isFilled ||
      !onlineCart ||
      !onlineCart?.delivery ||
      increaseStatus === "pending" ||
      decreaseStatus === "pending",
    [decreaseStatus, increaseStatus, isFilled, onlineCart]
  );

  return (
    <div>
      <div className="flex flex-col-reverse md:flex-row justify-between md:px-48 px-4 py-16">
        {/* shipping info */}
        <div className="flex flex-col">
          <h4 className="text-sm font-light pb-4">
            Fill your details below if you would like to make full payment for
            this order.
            <div></div>
          </h4>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <label
                htmlFor="fullname"
                className="block text-sm font-medium  lg:text-black "
              >
                Full name <span className="text-red-400">*</span>
              </label>
              <div className="mt-2 relative rounded-md shadow-sm">
                <TextInput
                  {...register("input.name", { required: true })}
                  type="text"
                  required
                  id="fullname"
                  className="focus:ring-sokoBlue focus:border-sokoBlue block w-80%   pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Name"
                />
              </div>
            </div>
            <div className="mb-6">
              <label
                htmlFor="phone"
                className="block text-sm font-medium lg:text-black "
              >
                Phone number <span className="text-red-400">*</span>
              </label>
              <div className="mt-2 relative rounded-md shadow-sm">
                <TextInput
                  {...register("input.phone", { required: true })}
                  type="text"
                  required
                  id="phone"
                  className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                  placeholder=" 0202718168"
                />
              </div>
            </div>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block text-sm font-medium lg:text-black "
              >
                Email <span className="text-red-400">*</span>
              </label>
              <div className="mt-2 relative rounded-md shadow-sm">
                <TextInput
                  {...register("input.email", { required: true })}
                  type="email"
                  required
                  id="email"
                  className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                  placeholder=" example@gmail.com"
                />
              </div>

              {/* google places code */}
              <div className="mb-6 mt-6">
                <label
                  htmlFor="Area"
                  className="block text-sm font-medium  lg:text-black "
                >
                  Area <span className="text-red-400">*</span>
                </label>

                <PlacesAutocomplete
                  // {...register("input.address.area", { required: true })}
                  className="w-1/2"
                  value={address}
                  onChange={(value: string) => setAddress(value)}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }: {
                    getInputProps: any;
                    suggestions: any;
                    getSuggestionItemProps: any;
                    loading: any;
                  }) => (
                    <div>
                      <TextInput
                        {...register("input.address.area", { required: true })}
                        {...getInputProps({
                          placeholder: "Search Area ...",
                          className: "location-search-input",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(
                          (suggestion: {
                            active: any;
                            description:
                              | string
                              | number
                              | boolean
                              | React.ReactElement<
                                  any,
                                  string | React.JSXElementConstructor<any>
                                >
                              | React.ReactFragment
                              | React.ReactPortal
                              | null
                              | undefined;
                          }) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>

              {/* google places code */}
              <div className="mb-6">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium  lg:text-black "
                >
                  Address
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <TextInput
                    {...register("input.address.city", { required: true })}
                    type="text"
                    required
                    id="city"
                    value={city}
                    className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Address"
                  />
                </div>
              </div>

              <div className="mb-6">
                <label
                  htmlFor="landmark"
                  className="block text-sm font-medium  lg:text-black "
                >
                  Landmark
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <TextInput
                    {...register("input.address.landmark")}
                    type="text"
                    id="landmark"
                    className="focus:ring-sokoBlue focus:border-sokoBlue block w-80%   pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Landmark"
                  />
                </div>
              </div>
              {!isFilled ? (
                <div>
                  <div className="flex flex-row space-x-4 pt-6">
                    <div className="w-4 h-4  mt-1 rounded border-blue-400 border-2"></div>
                    <div className="font-medium">Create an account?</div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row space-x-4 pt-6">
                  <div className="w-4 h-4 bg-blue-400 mt-1 rounded border-blue-400 border-2">
                    <CheckIcon className="text-white " />
                  </div>
                  <div className="font-medium">Create an account?</div>
                </div>
              )}

              <div className=" font-light text-sm">
                Your details will be saved and used for other related shops.
              </div>
              <button
                hidden
                className="hidden"
                type="submit"
                ref={formBtnRef}
              ></button>
            </div>
          </form>

          {/* </div> */}
          {/* shipping info */}

          {/* Delivery Page */}
          <div>
            <Tabs value={activeTab} onTabChange={setActiveTab}>
              <Tabs.Panel value="delivery">
                <div className="flex flex-col my-4">
                  <select
                    name=""
                    id="select"
                    className="rounded-md border border-gray-300 px-2 py-3"
                    onChange={(e) => {
                      const location = JSON.parse(e.target.value);
                      const location_price = location?.price;
                      dispatch(setCustomerDeliveryLocation(location));
                      dispatch(
                        addDeliveryToStorefrontCart({
                          cart_id: onlineCart?.id as string,
                          delivery: {
                            delivery_amount: location_price,
                            delivery_id: location?.id,
                            delivery_type: "DELIVERY",
                            instantDelivery: false,
                          },
                        })
                      );
                    }}
                  >
                    <option value="">Select Delivery Zone (required)</option>
                    {deliveryLocations.map((location, idx) => (
                      <option
                        key={idx}
                        value={JSON.stringify(location)}
                        className="flex w-64 whitespace-nowrap overflow-hidden text-ellipsis"
                      >
                        {location?.location} - {location?.price}
                      </option>
                    ))}
                  </select>
                </div>
              </Tabs.Panel>
            </Tabs>

            <div className="bg-gray-300 p-3 rounded-md flex flex-col my-4">
              <span className="text-white">
                {customer_delivery_location?.location}
              </span>
              <span className="text-white">
                Price : {customer_delivery_location?.price}
              </span>
            </div>
          </div>
          <Fragment>
            <div className="flex lg:hidden flex-col lg:mx-auto lg:space-x-32">
              <div className="p-3 h-fit w-full rounded-sm space-y-3">
                <h2 className="font-medium text-xl">Order Summary </h2>

                <div className="space-y-4 w-full mb-11">
                  <div className="flex justify-between text-base">
                    <p className="font-light">Subtotal</p>
                    <p className="font-medium">
                      {onlineCart?.currency}{" "}
                      {numeral(onlineCart?.subtotal).format("#,#")}
                    </p>
                  </div>
                  <Show if={false}>
                    <div className="flex justify-between text-base">
                      <p className="font-light">Discount</p>
                      <p className="font-medium">{onlineCart?.currency} 100</p>
                    </div>
                  </Show>
                  <div className="flex justify-between text-base">
                    <p className="font-light">Delivery</p>
                    <p className="font-medium">
                      {onlineCart?.currency}{" "}
                      {numeral(customer_delivery_location?.price).format(
                        "#,#"
                      ) ?? "N/A"}
                    </p>
                  </div>
                  {/* <hr /> */}
                  <div className="flex justify-between text-base">
                    <p className="font-light">TOTAL</p>
                    <p className="font-medium">
                      {onlineCart?.currency}{" "}
                      {numeral(onlineCart?.total).format("#,#")}
                    </p>
                  </div>
                  <div className="flex w-full justify-between text-sm">
                    <p className="font-light">Est. delivery date</p>
                    <p className="font-medium">
                      {startDateArr[0] + " " + startDateArr[1]} &{" "}
                      {endDateArr[0] + " " + endDateArr[1]}
                    </p>
                  </div>
                </div>
                <div className="my-11">
                  {errorNotification.show &&
                    notify({
                      type: "error",
                      message: errorNotification.message,
                      dismissible: true,
                    })}
                  {disableButton ? (
                    <div
                      onClick={handleClick}
                      className="bg-gray-400 p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-gray-500 w-full flex-1 text-center text-"
                    >
                      {" "}
                      {`Pay ${onlineCart?.currency} ${onlineCart?.total}`}
                    </div>
                  ) : (
                    <DukePaymentButton
                      text={`Pay ${onlineCart?.currency} ${onlineCart?.total}`}
                      className="bg-pink-600 p-3 hover:bg-white rounded-md hover:border hover:border-black hover:text-black text-white w-full flex-1"
                      billingEmail={userInfo?.email || ""}
                      firstName={_.first(userInfo?.name.split(" ")) ?? ""}
                      lastName={_.last(userInfo?.name.split(" ")) ?? ""}
                      phone={userInfo?.phone ?? ""}
                      amount={onlineCart?.total as number}
                      orderId=""
                      onCancel={() => alert("Payment not Successful")}
                      onSuccess={() => alert("Success")}
                    />
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        </div>
        {/* Delivery Page */}
        <div className="flex flex-col">
          {/* Cart items Preview */}
          <div className="py-4 mb-6 space-y-4">
            {onlineCart?.items?.map((item, idx) => (
              <div
                key={idx}
                className="flex justify-between flex-col md:flex-col items-start"
              >
                <div className="flex space-x-3">
                  <div className="w-[160px] h-[160px] md:hidden rounded-md overflow-hidden">
                    <img
                      src={product?.avatar}
                      className="h-full w-full object-cover"
                      alt={product?.title}
                    />
                  </div>
                  <Tab.Group as="div" className="flex flex-col-reverse -mr-16">
                    <div className="mx-auto mt-6 hidden w-full max-w-2xl  sm:block lg:max-w-none">
                      <Tab.List className="grid grid-cols-4 gap-6">
                        {product?.media?.map((image: any, idx: number) => (
                          <Tab
                            key={idx + 1}
                            className="relative flex h-12 w-12 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                          >
                            <span className="absolute inset-0 overflow-hidden rounded-md">
                              <img
                                src={image?.avatar ?? ""}
                                alt=""
                                className="h-full w-full object-cover object-center"
                              />
                            </span>
                            <span
                              aria-hidden="true"
                              className="pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-pink-500"
                            />
                          </Tab>
                        ))}
                      </Tab.List>
                    </div>

                    <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
                      {product?.media?.map((image: any) => (
                        <Tab.Panel key={image?.id}>
                          <img
                            src={product?.avatar}
                            alt={product?.title!}
                            className="h-full w-full object-center sm:rounded-lg"
                          />
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                  </Tab.Group>

                  <div className="flex flex-col items-start space-y-2 lg:space-y-8 md:pl-6 ">
                    <span className="pb-1 text-sm font-medium">
                      {item?.title}
                    </span>
                    <span className="font-medium text-sm">
                      {onlineCart?.currency} {sellingPrice}
                    </span>
                    <span className="text-xs font-semibold max-w-max bg-gray-200 rounded-md p-1">
                      {product?.combination_string?.split("&").join(", ")}
                    </span>

                    <span className="font-medium text-xs">
                      {product?.qty} Pieces available in stock
                    </span>

                    <div className="flex space-x-2 items-center flex-col lg:flex-row ">
                      <div className="border flex justify-between w-36 border-gray-400 rounded-md p-1 items-center">
                        <button
                          onClick={() => {
                            setDecreaseStatus("pending");
                            dispatch(decreaseQty(idx));
                            dispatch(
                              adjustStorefrontCartItemQuantity({
                                item_id: item?.id as string,
                                type: QuantityAdjustmentType.Decrease,
                              })
                            )
                              .unwrap()
                              .then(() => setDecreaseStatus("success"))
                              .finally(() => setDecreaseStatus("idle"));
                          }}
                        >
                          {decreaseStatus === "pending" ? (
                            <div className="h-6 w-6 rounded-full border-t-2 border-l-2 border-gray-500 animate-spin" />
                          ) : (
                            <MinusIcon className="h-6 w-6 hover:cursor-pointer" />
                          )}
                        </button>
                        <div>{item?.quantity}</div>
                        <button
                          onClick={() => {
                            setIncreaseStatus("pending");
                            dispatch(increaseQty(idx));
                            dispatch(
                              adjustStorefrontCartItemQuantity({
                                item_id: item?.id as string,
                                type: QuantityAdjustmentType.Increase,
                              })
                            )
                              .unwrap()
                              .then(() => setIncreaseStatus("success"))
                              .finally(() => setIncreaseStatus("idle"));
                          }}
                        >
                          {increaseStatus === "pending" ? (
                            <div className="h-6 w-6 rounded-full border-t-2 border-l-2 border-gray-500 animate-spin" />
                          ) : (
                            <PlusIcon className="h-6 w-6 hover:cursor-pointer" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Fragment>
            <div className="hidden lg:flex lg:w-full lg:flex-col lg:mx-auto">
              <div className="p-4 h-fit w-full rounded-sm space-y-3">
                <h2 className="font-medium text-xl">Order Summary </h2>

                <div className="space-y-4 w-full mb-11">
                  <div className="flex justify-between text-sm">
                    {/* <p className="font-light">{plural("Item", cartLength, true)}</p> */}
                    <p className="font-medium"></p>
                  </div>
                  <div className="flex justify-between text-sm">
                    <p className="font-light">Subtotal</p>
                    <p className="font-medium">
                      {onlineCart?.currency}{" "}
                      {numeral(onlineCart?.subtotal).format("#,#")}
                    </p>
                  </div>
                  <Show if={false}>
                    <div className="flex justify-between text-sm">
                      <p className="font-light">Discount</p>
                      <p className="font-medium">{onlineCart?.currency} 100</p>
                    </div>
                  </Show>
                  <div className="flex justify-between text-sm">
                    <p className="font-light">Delivery</p>
                    <p className="font-medium">
                      {onlineCart?.currency}{" "}
                      {numeral(onlineCart?.delivery?.delivery_amount).format(
                        "#,#"
                      ) ?? "N/A"}
                    </p>
                  </div>
                  {/* <hr /> */}
                  <div className="flex justify-between text-sm">
                    <p className="font-light">Total</p>
                    <p className="font-medium">
                      {onlineCart?.currency}{" "}
                      {numeral(onlineCart?.total).format("#,#")}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between w-full text-sm">
                    <div className="font-light">Est delivery date:</div>
                    <p className="font-medium">
                      {startDateArr[0] + " " + startDateArr[1]} &{" "}
                      {endDateArr[0] + " " + endDateArr[1]}
                    </p>
                  </div>
                </div>
                <div className="">
                  {errorNotification.show &&
                    notify({
                      type: "error",
                      message:
                        "Kindly fill the form and select a delivery location.",
                      dismissible: true,
                    })}
                  {disableButton ? (
                    <div
                      onClick={handleClick}
                      className="bg-gray-400 p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-gray-500  w-full flex-1 text-center"
                    >
                      {`Pay ${onlineCart?.currency} ${onlineCart?.total}`}
                    </div>
                  ) : (
                    <DukePaymentButton
                      text={`Pay ${onlineCart?.currency} ${onlineCart?.total}`}
                      className="bg-pink-600 p-3 hover:bg-white rounded-md hover:border hover:border-black hover:text-black text-white  w-full flex-1"
                      billingEmail={userInfo?.email || ""}
                      firstName={_.first(userInfo?.name.split(" ")) ?? ""}
                      lastName={_.last(userInfo?.name.split(" ")) ?? ""}
                      phone={userInfo?.phone ?? ""}
                      amount={onlineCart?.total as number}
                      orderId=""
                      onCancel={() => alert("Payment not Successful")}
                      onSuccess={() => alert("Success")}
                    />
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default SingleCheckoutPage;
