import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import ErrorPage from "./layouts/404/404";
import AccountLayout from "./layouts/AccountLayout/AccountLayout";
import StoreFront from "./layouts/StoreFront/StoreFront";
import AddressPage from "./pages/AddressPage/AddressPage";
import CartLayout from "./pages/CartLayout";
import CartPage from "./pages/CartPage";
import Checkout from "./pages/Checkout";
import Collection from "./pages/Collection/Collection";
import ConfirmDigitalOrderPayment from "./pages/ConfirmDigitalOrderPayment/ConfirmDigitalOrderPayment";
import ConfirmPayment from "./pages/ConfirmPayment/ConfirmPayment";
import DeliveryPage from "./pages/DeliveryPage";
import DigitalCheckout1 from "./pages/DigitalCheckout1/DigitalCheckout1";
import DigitalProductDeliveryPage from "./pages/DigitalProductDeliveryPage.tsx/DigitalProductDeliveryPage";
import DigitalProductDetail from "./pages/DigitalProductDetail/DigitalProductDetail";
import OrderPage from "./pages/DigitalProductOrderPage/OrderPage";
import HomePage from "./pages/HomePage";
import MakePayment from "./pages/MakePayment/MakePayment";
import Orders from "./pages/Orders/Orders";
import PaymentInformation from "./pages/PaymentInformation/PaymentInformation";
import PersonalInformation from "./pages/PersonalInformation/PersonalInformation";
import Product from "./pages/Product/Product";
import RecentlyViewed from "./pages/RecentlyViewed/RecentlyViewed";
import ShippingInfo from "./pages/ShippingInfo/ShippingInfo";
import SingleCheckoutPage from "./pages/SingleCheckoutPage/SingleCheckoutPage";

import DigitalOrderSuccessPage from "./pages/DigitalOrderSuccessPage/DigitalOrderSuccessPage";
import SuccessPayment from "./pages/SuccessPayment";
import { HelmetProvider } from "react-helmet-async";
import Order from "./layouts/TrackOrder/Order";
import OrderDetails from "./layouts/TrackOrder/OrderDetails";
import TrackOrder from "./layouts/TrackOrder/TrackOrder";
import InputOTPPage from "./pages/InputOTPPage/InputOTPPage";
import PreviewDigitalProduct from "./pages/PreviewDigitalProduct/PreviewDigitalProduct";
import StorefrontOrderPage from "./pages/StorfrontOrderPage/StorefrontOrderPage";

import { pdfjs } from "react-pdf";
import SingleCheckOutInitialPage from "./pages/SingleCheckoutPage/SingleCheckOutInitialPage";

type Props = {};

const App = (props: Props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

  return (
    <HelmetProvider>
      <Router>
        <Routes>
          {/* <Route path="/auth" element={<AuthLayout />}>
          <Route index element={<PhoneInput />} />
          <Route path="email" element={<EmailInput />} />
          <Route path="verify-otp" element={<VerifyOtp />} />
        </Route> */}

          {/* <Route path="/account" element={<AccountLayout />}>
          <Route index element={<PersonalInformation />} />
          <Route path="paymentInformation" element={<PaymentInformation />} />
          <Route path="orders" element={<Orders />} />
          <Route path="recentlyViewed" element={<RecentlyViewed />} />
        </Route> */}

          <Route path="/" element={<StoreFront />}>
            <Route index element={<HomePage />} />

            <Route path="/cart" element={<CartLayout />}>
              <Route index element={<CartPage />} />
              <Route path="shippinginfo" element={<ShippingInfo />} />
              <Route path="address" element={<AddressPage />} />
              <Route path="delivery" element={<DeliveryPage />} />
              <Route path="final" element={<ConfirmPayment />} />
            </Route>
            <Route path="/account" element={<AccountLayout />}>
              <Route index element={<PersonalInformation />} />
              <Route
                path="paymentInformation"
                element={<PaymentInformation />}
              />
              <Route path="orders" element={<Orders />} />
              <Route path="recentlyViewed" element={<RecentlyViewed />} />
            </Route>
            <Route path="/Orders">
              <Route index element={<TrackOrder />} />
            </Route>
            <Route path="/track-your-order" element={<OrderDetails />} />
            <Route path="/my-orders" element={<Order />} />
            <Route path="/product/:id" element={<Product />} />
            <Route
              path="/digital-product/:id"
              element={<DigitalProductDetail />}
            />
            <Route
              path="/self_checkout"
              element={<SingleCheckOutInitialPage />}
            />
            <Route path="/checkout-link" element={<SingleCheckoutPage />} />
            <Route path="/collection/:id" element={<Collection />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/confirm-payment" element={<ConfirmPayment />} />
            <Route
              path="/confirm-digital-order-payment/:id/:qty"
              element={<ConfirmDigitalOrderPayment />}
            />
            <Route path="/makepayment" element={<MakePayment />} />
            <Route path="/checkout/success" element={<SuccessPayment />} />
            <Route
              path="/digital-order-success/:id/:invoice"
              element={<DigitalOrderSuccessPage />}
            />
            <Route path="/input-otp/:id/:invoice" element={<InputOTPPage />} />
            <Route path="/order-digital-product" element={<OrderPage />} />
            <Route
              path="/storefront-digital-order/:id"
              element={<StorefrontOrderPage />}
            />
            <Route
              path="/digital-checkout-1/:id/:qty"
              element={<DigitalCheckout1 />}
            />
            <Route
              path="/input-otp-page/:token/:invoice"
              element={<DigitalCheckout1 />}
            />
            <Route
              path="/preview-digital-product/:id"
              element={<PreviewDigitalProduct />}
            />
            <Route
              path="/digital-product-delivery/:id/:qty"
              element={<DigitalProductDeliveryPage />}
            />
          </Route>
          <Route path="/404page" element={<ErrorPage />} />
          {/* </Route> */}
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

export default App;
