import { gql } from "@apollo/client";

export const CUSTOMER_SIGNUP = gql`
  mutation CustomerSignUp($userId: ID!, $input: CustomerInput) {
    customerSignUp(user_id: $userId, input: $input) {
      user {
        id
        name
        email
        phone
        address {
          country
          city
          area
          landmark
          lat
          lng
          id
        }
        reseller {
          id
          shop {
            id
          }
          email
          name
          country
        }
      }
      token
    }
  }
`;

export const CUSTOMER_SIGNIN = gql`
  mutation CustomerSignIn($input: CustomerLoginInput) {
    customerSignIn(input: $input) {
      token
      user {
        id
        email
        name
        phone
        address {
          area
          city
          country
          landmark
        }
        reseller {
          name
          phone
          email
          country
          username
        }
      }
    }
  }
`;

export const CREATE_CUSTOMER_ADDRESS = gql`
  mutation CreateCustomerAddress($customerId: ID!, $input: AddressInput) {
    createCustomerAddress(customer_id: $customerId, input: $input) {
      id
      landmark
      area
      city
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation CreateOrder($input: OrderCreateInput) {
    createOrder(input: $input)
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOTP($input: verifyOTPInput) {
    verifyOTP(input: $input)
  }
`;
export const REQUEST_OTP = gql`
  mutation RequestOTP($input: requestOTPInput) {
    requestOTP(input: $input) {
      token
    }
  }
`;
export const VERIFY_ORDER_OTP = gql`
  mutation VerifyOrderOtp($orderId: ID!, $otp: String!, $customerId: ID!) {
    verifyOrderOtp(order_id: $orderId, otp: $otp, customer_id: $customerId)
  }
`;
export const REQUEST_ORDER_OTP = gql`
  mutation GetOrderOtp($orderId: ID!, $customerId: ID!) {
    getOrderOtp(order_id: $orderId, customer_id: $customerId)
  }
`;
export const UPDATE_CUSTOMER_INFORMATION = gql`
  mutation UpdateCustomer($input: CustomerInput) {
    updateCustomer(input: $input)
  }
`;
export const DELETE_CUSTOMER_ADDRESS = gql`
  mutation RemoveCustomerAddress($removeCustomerAddressId: ID!) {
    removeCustomerAddress(id: $removeCustomerAddressId)
  }
`;

export const getCheckoutLink = gql`
  mutation GetCheckOutLInk($data: MakeTinggPaymentRequestDto) {
    makePayment(data: $data)
  }
`;

export const CREATE_DIGITAL_ORDER = gql`
  mutation CreateDigitalOrder($input: DigitalOrderInput!) {
    createDigitalOrder(input: $input)
  }
`;

export const CREATE_CART = gql`
  mutation CreateStorefrontCart($input: CreateStorefrontCartInput!) {
    createStorefrontCart(input: $input) {
      id
      identity
      items {
        id
        price
        product_id
        product_price
        quantity
        sku
        cart_id
        title
        reseller_profit
      }
      customer {
        address_id
        customer_id
      }
      delivery {
        delivery_amount
        delivery_id
        delivery_type
        instantDelivery
      }
      payment {
        amount
        discount
        invoice
        payment_id
        payment_status
        payment_type
      }
      shop_id
      subtotal
      total
      status
      created_at
    }
  }
`;

export const ADD_ITEM_TO_CART = gql`
  mutation AddItemToStoreFrontCart($input: AddItemToStorefrontCartInput!) {
    addItemToStoreFrontCart(input: $input) {
      id
      identity
      items {
        id
        price
        product_id
        title
        product_price
        quantity
        sku
        cart_id
        reseller_profit
      }
      customer {
        address_id
        customer_id
      }
      delivery {
        delivery_amount
        delivery_id
        delivery_type
        instantDelivery
      }
      payment {
        amount
        discount
        invoice
        payment_id
        payment_status
        payment_type
      }
      shop_id
      subtotal
      total
      status
      created_at
    }
  }
`;
