import _ from "lodash";
import numeral from "numeral";
import { Fragment, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DukePaymentButton from "../../components/duke-payment/duke-payment";
import Show from "../../components/Show";
import useCart from "../../hooks/useCart";

import { useAppSelector } from "../../app/hooks";

const Cart = () => {
  const navigate = useNavigate();
  const { cartLength, onlineCart, deliveryPrice } = useCart();
  const { userInfo } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();
  const currency = useMemo(() => onlineCart?.currency ?? "", [onlineCart]);
  const productsTotal = useMemo(
    () => onlineCart?.subtotal ?? 0,
    [onlineCart?.subtotal]
  );
  const total = useMemo(() => onlineCart?.total ?? 0, [onlineCart?.total]);

  return (
    <Fragment>
      <div
        //  className=" p-4 lg:max-w-7xl  flex lg:flex-row   flex-col  lg:mx-auto lg:space-x-32"
        className={`p-4 lg:max-w-7xl flex lg:flex-row flex-col lg:mx-auto lg:space-x-32 ${
          pathname === "/cart/shippinginfo"
            ? "p-4 lg:max-w-7xl flex lg:flex-row flex-col mt-20 lg:mx-auto lg:space-x-32"
            : "p-4 lg:max-w-7xl flex lg:flex-row flex-col lg:mx-auto lg:space-x-32"
        }`}
      >
        <Helmet>
          <title>Cart</title>
        </Helmet>
        <div className="flex-1">
          <Outlet />
        </div>
        <div className="border-gray-300 py-3 md:mt-16 h-fit lg:w-96 w-full rounded-sm space-y-3">
          <h3 className="font-bold text-xl">Order Summary </h3>
          <hr />

          <div className="space-y-4 w-full mb-11">
            <div className="flex justify-between text-base">
              <p className="font-light">Item(s)</p>
              <p className="font-medium">{cartLength}</p>
            </div>
            <div className="flex justify-between text-base">
              <p className="font-light">Subtotal</p>
              <p className="font-medium">
                {currency} {numeral(productsTotal).format("#,#.##")}
              </p>
            </div>
            <Show if={false}>
              <div className="flex justify-between text-base">
                <p className="font-light">Discount</p>
                <p className="font-medium">{currency} 100</p>
              </div>
            </Show>
            <div className="flex justify-between text-base">
              <p className="font-light">Delivery</p>
              <p className="font-medium">
                {currency} {numeral(deliveryPrice).format("#,#.##") ?? "N/A"}
              </p>
            </div>

            <div className="flex justify-between text-base">
              <p className="font-light">TOTAL</p>
              <p className="font-medium">
                {currency} {numeral(total).format("#,#.##")}
              </p>
            </div>
            <div className="my-11">
              {pathname === "/cart/delivery" ? (
                <DukePaymentButton
                  text={`Pay ${currency} ${total}`}
                  className="bg-pink-600 p-3 hover:bg-white rounded-md hover:border hover:border-black hover:text-black text-white  w-full flex-1"
                  billingEmail={userInfo?.email || ""}
                  firstName={_.first(userInfo?.name.split(" ")) ?? ""}
                  lastName={_.last(userInfo?.name.split(" ")) ?? ""}
                  phone={userInfo?.phone ?? ""}
                  amount={total}
                  orderId=""
                  onCancel={() => alert("Payment not Successful")}
                  onSuccess={() => alert("Success")}
                />
              ) : pathname === "/cart/shippinginfo" ? (
                <button className="hidden md:hidden bg-white"></button>
              ) : (
                <button
                  onClick={() => navigate(_.get(nextPages, pathname))}
                  className="bg-pink-600 p-3 hover:bg-white hover:border hover:border-black hover:text-black text-white rounded-md w-full flex-1"
                >
                  {_.get(nextPagesLabel, pathname)}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Cart;

const nextPages = {
  "/cart": "/cart/shippinginfo",
  "/cart/shippinginfo": "/cart/address",
  "/cart/address": "/cart/delivery",
  "/cart/delivery": "/cart/final",
};

const nextPagesLabel = {
  "/cart": "Proceed to Checkout",

  "/cart/address": "Proceed to Delivery",
  "/cart/delivery": "Proceed to Payment",
  "/cart/final": "Place Order",
};
