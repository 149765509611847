import clsx from "clsx";
import _ from "lodash";
import { Link } from "react-router-dom";
import { addToCart, removeFromCart } from "../../features/cart/cart-slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useMemo } from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ShoppingBagIcon } from "@heroicons/react/20/solid";

type Props = {
  product: any;
};

const ProductCard = ({ product }: Props) => {
  const { onlineCart, addingToCartState, removingFromCartState } =
    useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const item = useMemo(
    () => onlineCart?.items?.find((el) => el?.product_id === product?.id),
    [onlineCart?.items, product?.id]
  );
  const alreadyAdded = useMemo(() => item !== undefined, [item]);
  const isAddingToCart = useMemo(
    () =>
      addingToCartState &&
      product.id === addingToCartState.itemId &&
      addingToCartState.status === "loading",
    [addingToCartState, product.id]
  );
  const isRemovingItemFromCart = useMemo(
    () =>
      removingFromCartState &&
      product.id === removingFromCartState.itemId &&
      removingFromCartState.status === "loading",
    [removingFromCartState, product.id]
  );

  function limitTitle(sentence: string, maxWidth: number) {
    if (sentence.length <= maxWidth) {
      return sentence;
    } else {
      return sentence.slice(0, maxWidth - 3) + "...";
    }
  }
  const originalSentence = product?.title;
  const maxWidth = 32;
  const limitedTitle = limitTitle(originalSentence, maxWidth);

  return (
    <div className="group relative flex flex-col overflow-hidden rounded-lg bg-white">
      <div className="h-56 w-full overflow-hidden bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
        <Link
          to={{
            pathname: `/product/${product?.id}`,
          }}
        >
          <img
            src={product?.avatar}
            alt={product?.title}
            className="h-full object-cover w-full"
          />
        </Link>
      </div>

      <div className="flex flex-col w-full space-y-2 p-2">
        <Link
          to={{
            pathname: `/product/${product?.id}`,
          }}
        >
          <h3 aria-hidden="true" className="text-xs text-gray-900">
            {limitedTitle}
          </h3>
        </Link>

        <p className="font-bold pt-0.5 text-gray-400 text-sm">
          {product?.currency} {_.sum([product?.price, product?.profit_added])}
        </p>
        <div className="flex flex-col space-y-2 py-2">
          {alreadyAdded && (
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row space-x-0.5 items-center">
                <ShoppingBagIcon className="h-3 w-3 text-green-600" />
                <span className="text-xs">Already in cart</span>
              </div>
            </div>
          )}
          <div className="flex flex-row w-full space-x-2">
            <button
              onClick={() => dispatch(addToCart(product))}
              className={clsx(
                "font-medium w-full px-2 py-2 rounded-md flex items-center justify-center text-sm text-black bg-white border border-gray-500"
              )}
              disabled={isRemovingItemFromCart}
            >
              {isAddingToCart ? (
                <div className="h-4 w-4 rounded-full border-t-2 border-l-2 border-pink-400 animate-spin" />
              ) : (
                "Add to Cart"
              )}
            </button>
            {alreadyAdded && (
              <button
                onClick={() => dispatch(removeFromCart(product))}
                className="font-medium p-2 rounded-md bg-red-500 text-sm flex items-center justify-center"
                disabled={isAddingToCart}
              >
                {isRemovingItemFromCart ? (
                  <div className="h-4 w-4 rounded-full border-t-2 border-l-2 border-pink-400 animate-spin" />
                ) : (
                  <TrashIcon className="w-5 h-5 text-white" />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
