import { useMemo } from "react";
import { useAppSelector } from "./../app/hooks";

const useCart = () => {
  const {
    cartItems,
    cartTotal,
    customer_address,
    customer_delivery_location,
    deliveryPrice,
    onlineCart,
  } = useAppSelector((state) => state.cart);
  const totalItem = useMemo(
    () =>
      onlineCart?.items?.reduce(
        (count, item) => count + Number(item?.quantity ?? 0),
        0
      ) ?? 0,
    [onlineCart]
  );
  return {
    cartItems,
    cartLength: totalItem,
    total: cartTotal,
    deliveryPrice,
    customer_address,
    customer_delivery_location,
    onlineCart,
  };
};

export default useCart;
