import { CheckIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import DukePaymentButton from "../../components/duke-payment/duke-payment";
import {
  addPaymentToStorefrontCart,
  clearCart,
  getStorefrontCart,
  updateStorefrontCartStatus,
} from "../../features/cart/cart-slice";
import { StorefrontCartStatusType } from "../../graphql/types";
import useCart from "../../hooks/useCart";
import { useCreateOrder } from "../../hooks/useCreateOrder";
import { useVerifyPaymentWithReference } from "../../hooks/useVerifyPaymentWithReference";

const ConfirmPayment = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userInfo } = useAppSelector((state) => state.auth);
  const { createOrder, loading, error } = useCreateOrder();
  const { cartItems, onlineCart } = useCart();
  const { shop } = useAppSelector((state) => state.shop);
  const dispatch = useAppDispatch();

  const urlSearchData = new URLSearchParams(searchParams);
  const reference = urlSearchData.get("reference");
  const cart_id = urlSearchData.get("cart_id");
  const isSuccessful = urlSearchData.get("success") === "true";

  const init = useRef({
    dispatch,
    getStorefrontCart,
    createOrder,
    navigate,
    addPaymentToStorefrontCart,
    updateStorefrontCartStatus,
  });
  const {
    data,
    error: errorVerifying,
    loading: verifying,
    refetch,
  } = useVerifyPaymentWithReference(reference as string);
  const isCorrectAmount = useMemo(
    () => data?.amount === onlineCart?.total,
    [data?.amount, onlineCart?.total]
  );

  const createStorefrontOrder = useCallback(async () => {
    const {
      dispatch,
      createOrder,
      navigate,
      addPaymentToStorefrontCart,
      updateStorefrontCartStatus,
    } = init.current;
    const totalAmount = onlineCart?.total as number;
    await createOrder({
      variables: {
        input: {
          customer_id: userInfo?.id ?? "",
          products: cartItems.map((item) => {
            const onlineItem = onlineCart?.items?.find(
              (el) => el?.product_id === item.id
            );
            return {
              price: Number(onlineItem?.price),
              product_id: String(onlineItem?.sku),
              profitAdded: item?.profit_added ?? 0,
              qty: Number(onlineItem?.quantity),
              cost_price: item?.cost_price,
            };
          }),
          reseller: shop?.ownerId ?? "",
          total_amount: totalAmount,
          address_id: onlineCart?.customer?.address_id,
          delivery_id: onlineCart?.delivery?.delivery_id,
          delivery_type: "DELIVERY",
          discount: 0,
          payment_id: reference,
          payment_status: "FULL PAYMENT",
          payment_type: "PON",
          source: "store-front",
          delivery_amount: onlineCart?.delivery?.delivery_amount as number,
          free_delivery: false,
          customer_paid: true,
          instantDelivery: onlineCart?.delivery?.instantDelivery ?? false,
        },
      },
      onCompleted: ({ createOrder }) => {
        const cart_id = onlineCart?.id;
        dispatch(
          addPaymentToStorefrontCart({
            cart_id: cart_id as string,
            payment: {
              ...onlineCart?.payment,
              amount: data?.amount,
              payment_id: reference as string,
              payment_status: data?.status,
              payment_type: "PON",
            },
          })
        );
        dispatch(
          updateStorefrontCartStatus({
            cart_id: cart_id as string,
            status: StorefrontCartStatusType.Completed,
          })
        );
        dispatch(clearCart());
        navigate(
          `/checkout/success?order_id=${createOrder}&cart_id=${cart_id}`
        );
      },
      onError: (err) => {
        console.log("--ERROR--", err);
      },
    });
  }, [
    cartItems,
    data?.amount,
    data?.status,
    onlineCart?.customer?.address_id,
    onlineCart?.delivery?.delivery_amount,
    onlineCart?.delivery?.delivery_id,
    onlineCart?.delivery?.instantDelivery,
    onlineCart?.id,
    onlineCart?.items,
    onlineCart?.payment,
    onlineCart?.total,
    reference,
    shop?.ownerId,
    userInfo?.id,
  ]);

  useEffect(() => {
    const { dispatch, getStorefrontCart } = init.current;
    if (isSuccessful && cart_id) {
      dispatch(
        getStorefrontCart({
          id: {
            eq: cart_id,
          },
        })
      );
    }
  }, [cart_id, isSuccessful]);

  useEffect(() => {
    if (data?.status === "success" && onlineCart && isCorrectAmount) {
      createStorefrontOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.status, isCorrectAmount, onlineCart]);

  return (
    <main className="relative h-full w-full flex items-center justify-center p-5">
      <div className="overflow-hidden rounded-lg bg-white shadow w-full mx-auto md:min-w-80">
        <div className="px-4 py-5 sm:p-6">
          <div>
            {loading || verifying ? (
              <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-yellow-100">
                <svg
                  className="animate-spin h-6 w-6 text-yellow-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V2.5"
                  ></path>
                </svg>
              </div>
            ) : isSuccessful ? (
              <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                <CheckIcon
                  aria-hidden="true"
                  className="size-6 text-green-600"
                />
              </div>
            ) : !isSuccessful || errorVerifying ? (
              <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-yellow-100">
                <ExclamationCircleIcon
                  aria-hidden="true"
                  className="size-6 text-yellow-600"
                />
              </div>
            ) : null}
            <div className="mt-3 text-center sm:mt-5">
              <h3 className="text-base font-semibold text-gray-900">
                {verifying
                  ? "Verifying payment"
                  : loading
                  ? "Creating order"
                  : isSuccessful
                  ? "Payment successful"
                  : !isSuccessful || errorVerifying
                  ? data?.status === "initiated"
                    ? "Payment is pending"
                    : "Payment not completed"
                  : null}
              </h3>
              <div className="mt-2">
                {verifying ? (
                  <p className="text-sm text-gray-500">
                    Please, wait while we verify your payment. This will take a
                    few seconds.
                  </p>
                ) : loading ? (
                  <p className="text-sm text-gray-500">
                    Please wait! Creating your order
                  </p>
                ) : isSuccessful ? (
                  <p className="text-sm text-gray-500">
                    Thank you, the payment was successful. Your order will be
                    created.
                  </p>
                ) : !isSuccessful || errorVerifying ? (
                  <p className="text-sm text-gray-500">
                    Sorry, we could not verify your payment. If you didn't
                    complete the payment, make payment or try verifying again.
                  </p>
                ) : error ? (
                  <p className="text-sm text-gray-500">
                    Sorry Order placement couldn't go through for a reason.. try
                    again
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {verifying && (
          <div className="bg-gray-50 px-4 py-4 sm:px-6">
            {error ? (
              <button
                type="button"
                onClick={createStorefrontOrder}
                className="inline-flex w-full justify-center rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600"
              >
                Try again
              </button>
            ) : !isSuccessful || errorVerifying ? (
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <DukePaymentButton
                  text={`Pay ${onlineCart?.currency} ${onlineCart?.total}`}
                  className="bg-pink-600 p-3 hover:bg-white rounded-md hover:border hover:border-black hover:text-black text-white  w-full flex-1"
                  billingEmail={userInfo?.email || ""}
                  firstName={_.first(userInfo?.name.split(" ")) ?? ""}
                  lastName={_.last(userInfo?.name.split(" ")) ?? ""}
                  phone={userInfo?.phone ?? ""}
                  amount={onlineCart?.total as number}
                  orderId=""
                  onCancel={() => alert("Payment not Successful")}
                  onSuccess={() => alert("Success")}
                />
                <button
                  type="button"
                  data-autofocus
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                  onClick={() =>
                    refetch({ data: { reference: reference as string } })
                  }
                >
                  I've completed payment
                </button>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </main>
  );
};

export default ConfirmPayment;
