import _ from "lodash";
import { useNavigate } from "react-router-dom";
import useCart from "../../hooks/useCart";
import { removeFromCart } from "../../features/cart/cart-slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

type Props = {};

const CartComponent = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { cartItems, cartLength, total } = useCart();
  const { shop } = useAppSelector((state) => state.shop);

  const handleGoToCheckout = () => {
    navigate("/checkout");
  };

  return (
    <div>
      <div>
        <ul className="-my-6 divide-y divide-gray-200">
          {cartItems?.map((item, index) => (
            <li className="flex py-6" key={index}>
              <div className="h-24 w-30 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                <img
                  src={item?.avatar}
                  alt="Salmon orange fabric pouch with match zipper, gray zipper pull, and adjustable hip belt."
                  className="h-full w-full p-2 object-cover object-center"
                />
              </div>
              <div className="ml-4 flex flex-1 flex-col">
                <div>
                  <div className="flex justify-between text-base font-medium text-gray-900">
                    <h3>{item?.title}</h3>
                    <p className="ml-4">
                      {item.currency} {_.sum([item?.price, item?.profit_added])}
                    </p>
                  </div>
                  {/* <p className="mt-1 text-sm text-gray-500">Salmon</p> */}
                </div>
                <div className="flex flex-1 items-end justify-between text-sm">
                  <p className="text-gray-500"></p>

                  <div className="flex">
                    <button
                      onClick={() => dispatch(removeFromCart(item))}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="border-t border-gray-200 py-6 px-4 sm:px-3">
        <div className="flex justify-between text-base font-medium text-gray-900">
          <p>Subtotal</p>
          <p>
            {shop?.currency} {total}
          </p>
        </div>
        <p className="mt-0.5 text-sm text-gray-500">
          Shipping and taxes calculated at checkout.
        </p>
        <div className="mt-6">
          <button
            disabled={cartLength < 1}
            onClick={handleGoToCheckout}
            className="flex disabled:bg-gray-400 disabled:cursor-wait  w-full items-center justify-center rounded-md border border-transparent bg-mainPink px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-darkPink"
          >
            Checkout
          </button>
        </div>
        <div className="mt-6 flex flex-col justify-center text-center text-sm text-gray-500">
          <p>or</p>
          <button
            type="button"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Continue Shopping
            <span aria-hidden="true"> &rarr;</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartComponent;
