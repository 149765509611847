// import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import Loader from "./Loader";
import useProducts from "../../../hooks/useProducts";
import { useAppSelector } from "../../../app/hooks";
import Emptyshop from "./Emptycart.png";
import ProductCard from "../../../components/ProductCard/ProductCard";
import DigitalProductCard from "../../../components/DigitalProductCard/DigitalProductCard";
import Show from "../../../components/Show";
import clsx from "clsx";
import { ProductType } from "../../../graphql/types";

type Props = {};

const Products = (props: Props) => {
  const { shop } = useAppSelector((state) => state?.shop);
  const [regularproducts, setRegularProducts] = useState(true);

  const displayRegProducts = () => {
    setRegularProducts((prevState) => !prevState);
  };

  const { products, loading } = useProducts({
    shopId: shop?.id as string,
    filter: {
      productType: "Physical" as ProductType,
    },
  });

  const { products: digitalProducts, loading: loadingDigitalProducts } =
    useProducts({
      shopId: shop!.id,
      filter: {
        productType: "Digital" as ProductType,
      },
    });

  return (
    <Fragment>
      <Show if={loading}>
        <Loader />
      </Show>
      <Show if={products.length !== 0}>
        <div className=" justify-center items-center flex flex-row space-x-4 bg-[#F2F2F2] w-full">
          <div className="flex flex-col">
            <div>
              <h2
                onClick={displayRegProducts}
                id="product-heading"
                className={clsx(" text-lg m-2 font-normal  ", {
                  "text-black ": regularproducts,
                  "text-[#858585]": !regularproducts,
                })}
              >
                Regular products
              </h2>
            </div>
            <div
              className={clsx("", {
                "border w-36 border-black": regularproducts,
                hidden: !regularproducts,
              })}
            ></div>
          </div>
          <div className="flex flex-col">
            <div>
              <h2
                onClick={displayRegProducts}
                id="product-heading"
                className={clsx(" text-lg m-2 font-normal  ", {
                  "text-black ": !regularproducts,
                  "text-[#858585]": regularproducts,
                })}
              >
                Digital products
              </h2>
            </div>
            <div
              className={clsx("", {
                "border w-36 border-black": !regularproducts,
                hidden: regularproducts,
              })}
            ></div>
          </div>
        </div>
        <section
          aria-labelledby="product-heading"
          className="mx-auto max-w-xl px-2 pt-4 sm:p-6 lg:max-w-7xl lg:px-8"
        >
          {regularproducts ? (
            <div className="grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
              {products?.map((product, index) => (
                <ProductCard product={product} key={index} />
              ))}
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
              {digitalProducts?.map((product, index) => (
                <DigitalProductCard product={product} key={index} />
              ))}
            </div>
          )}
        </section>
      </Show>
      <Show if={products.length === 0 && !loading}>
        <div className="flex p-16 flex-col">
          <div className="text-3xl mb-4">Empty shop</div>
          <div className="text-lg">
            Oops, there are no products in this shop at the moment
          </div>
          <div className="m-auto">
            <img src={Emptyshop} alt="empty shop" />
          </div>
        </div>
      </Show>
    </Fragment>
  );
};

export default Products;
