import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  GetShopQuery,
  GetShopQueryVariables,
} from "../../graphql/queries/__generated__/queries";
import Cookies from "js-cookie";
import { RootState } from "../../app/store";
import { GRAPHQL_API_GATEWAY } from "../../constants";
import { GET_SHOP_STRING } from "../../graphql/rest-query-strings";

interface IShopFace {
  shop: GetShopQuery["getShop"] | null;
  loading: boolean;
}

export const getShop = createAsyncThunk(
  "shop/getShop",
  async (variables: GetShopQueryVariables, { getState }) => {
    const state = getState() as RootState;
    const token = Cookies.get("access_token");
    const country = state.shop.shop?.country ?? "GH";
    const response = await fetch(String(GRAPHQL_API_GATEWAY), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
        country,
        source: "store-front",
      },
      body: JSON.stringify({
        query: GET_SHOP_STRING,
        variables,
      }),
    });

    const { data } = await response.json();
    return data.getShop;
  }
);

const initialState: IShopFace = {
  shop: null,
  loading: false,
};

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setShop: (state, { payload }: PayloadAction<any>) => {
      state.shop = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShop.fulfilled, (state, { payload }) => {
        state.shop = payload;
        state.loading = false;
      })
      .addCase(getShop.pending, (state) => {
        state.loading = true;
      });
  },
});

export const { setShop } = shopSlice.actions;

export default shopSlice.reducer;
