export const CREATE_CART_STRING = `
  mutation CreateStorefrontCart($input: CreateStorefrontCartInput!) {
    createStorefrontCart(input: $input) {
        id
        identity
        items {
            id
            price
            product_id
            title
            product_price
            quantity
            sku
            cart_id
            reseller_profit
            avatar
        }
        customer {
        address_id
        customer_id
        }
        delivery {
        delivery_amount
        delivery_id
        delivery_type
        instantDelivery
        }
        payment {
        amount
        discount
        invoice
        payment_id
        payment_status
        payment_type
        }
        shop_id
        subtotal
        total
        status
        country
        currency
        created_at
    }
  }`;

export const ADD_ITEM_TO_CART_STRING = `
    mutation AddItemToStoreFrontCart($input: AddItemToStorefrontCartInput!) {
        addItemToStoreFrontCart(input: $input) {
            id
            identity
            items {
                id
                price
                product_id
                title
                product_price
                quantity
                sku
                cart_id
                reseller_profit
                avatar
            }
            customer {
            address_id
            customer_id
            }
            delivery {
            delivery_amount
            delivery_id
            delivery_type
            instantDelivery
            }
            payment {
            amount
            discount
            invoice
            payment_id
            payment_status
            payment_type
            }
            shop_id
            subtotal
            total
            status
            country
            currency
            created_at
        }
    }`;

export const REMOVE_ITEM_FROM_CART_STRING = `
    mutation RemoveItemFromStorefrontCart($input: RemoveItemFromStorefrontCartInput) {
        removeItemFromStorefrontCart(input: $input) {
            id
            identity
            items {
                id
                price
                product_id
                title
                product_price
                quantity
                sku
                cart_id
                reseller_profit
                avatar
            }
            customer {
            address_id
            customer_id
            }
            delivery {
            delivery_amount
            delivery_id
            delivery_type
            instantDelivery
            }
            payment {
            amount
            discount
            invoice
            payment_id
            payment_status
            payment_type
            }
            shop_id
            subtotal
            total
            status
            country
            currency
            created_at
        }
    }
`;

export const ADJUST_ITEM_QUANTITY_STRING = `
    mutation AdjustStorefrontCartItemQuantity($input: AdjustStorefrontCartItemQuantityInput!) {
        adjustStorefrontCartItemQuantity(input: $input) {
            id
            identity
            items {
                id
                price
                product_id
                title
                product_price
                quantity
                sku
                cart_id
                reseller_profit
                avatar
            }
            customer {
            address_id
            customer_id
            }
            delivery {
            delivery_amount
            delivery_id
            delivery_type
            instantDelivery
            }
            payment {
            amount
            discount
            invoice
            payment_id
            payment_status
            payment_type
            }
            shop_id
            subtotal
            total
            status
            country
            currency
            created_at
        }
    }
`;

export const UPDATE_STOREFRONT_CART_STATUS_STRING = `
    mutation UpdateStorefrontCartStatus($input: UpdateStorefrontCartStatusInput!) {
        updateStorefrontCartStatus(input: $input)
    }
`;

export const ADD_PAYMENT_TO_STOREFRONT_CART_STRING = `
    mutation AddPaymentToStorefrontCart($input: AddPaymentToStorefrontCartInput!) {
        addPaymentToStorefrontCart(input: $input) {
            id
            identity
            items {
                id
                price
                product_id
                title
                product_price
                quantity
                sku
                cart_id
                reseller_profit
                avatar
            }
            customer {
            address_id
            customer_id
            }
            delivery {
            delivery_amount
            delivery_id
            delivery_type
            instantDelivery
            }
            payment {
            amount
            discount
            invoice
            payment_id
            payment_status
            payment_type
            }
            shop_id
            subtotal
            total
            status
            country
            currency
            created_at
        }
    }
`;

export const ADD_CUSTOMER_TO_STOREFRONT_CART_STRING = `
    mutation AddCustomerToStorefrontCart($input: AddCustomerToStorefrontCartInput!) {
        addCustomerToStorefrontCart(input: $input) {
            id
            identity
            items {
                id
                price
                product_id
                title
                product_price
                quantity
                sku
                cart_id
                reseller_profit
                avatar
            }
            customer {
            address_id
            customer_id
            }
            delivery {
            delivery_amount
            delivery_id
            delivery_type
            instantDelivery
            }
            payment {
            amount
            discount
            invoice
            payment_id
            payment_status
            payment_type
            }
            shop_id
            subtotal
            total
            status
            country
            currency
            created_at
        }
    }
`;

export const ADD_DELIVERY_TO_STOREFRONT_CART_STRING = `
    mutation AddDeliveryToStorefrontCart($input: AddDeliveryToStorefrontCartInput!) {
        addDeliveryToStorefrontCart(input: $input) {
            id
            identity
            items {
                id
                price
                product_id
                title
                product_price
                quantity
                sku
                cart_id
                reseller_profit
                avatar
            }
            customer {
            address_id
            customer_id
            }
            delivery {
            delivery_amount
            delivery_id
            delivery_type
            instantDelivery
            }
            payment {
            amount
            discount
            invoice
            payment_id
            payment_status
            payment_type
            }
            shop_id
            subtotal
            total
            status
            country
            currency
            created_at
        }
    }
`;

export const CLEAR_CART_STRING = `
    mutation ClearStorefrontCart($input: ClearStorefrontCartInput!) {
        clearStorefrontCart(input: $input)
    }
`;

export const GET_SHOP_STRING = `
    query GetShop($filter: ShopFilter!) {
    getShop(filter: $filter) {
        id
        name
        description
        contactEmail
        email
        currency
        slug
        primaryDomain
        sokoWorldDomain
        customDomains
        hasBeenSetup
        isActive
        shopTags
        createdAt
        updatedAt
        avatar
        country
        coverImage
        deliveryOption
        deliveryAgent
        paymentOptions
        url
        shortUrl
        phone
        ownerId
        supplierId
        shopId
    }
    }
`;

export const GET_STOREFRONT_CART_STRING = `
    query GetStorefrontCart($filter: StorefrontCartFilter) {
        getStorefrontCart(filter: $filter) {
            id
            identity
            items {
                id
                price
                product_id
                title
                product_price
                quantity
                sku
                cart_id
                reseller_profit
                avatar
            }
            customer {
            address_id
            customer_id
            }
            delivery {
            delivery_amount
            delivery_id
            delivery_type
            instantDelivery
            }
            payment {
            amount
            discount
            invoice
            payment_id
            payment_status
            payment_type
            }
            shop_id
            subtotal
            total
            status
            country
            currency
            created_at
        }
    }`;
