import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useAppSelector } from "../app/hooks";
import { Transition } from "@headlessui/react";
import Content from "./Content";
import { useNotification } from "../hooks/useNotification";

const Notification = () => {
  const { notifications, showNotification } = useAppSelector(
    (state) => state.app
  );
  const notificationTimers = useMemo(
    () =>
      notifications
        ? new Set(notifications?.map((n) => ({ timer: n.timeout, id: n.id })))
        : [],
    [notifications]
  );
  const { dismiss } = useNotification();
  const init = useRef({ dismiss });
  const handleDismiss = useCallback((id: string) => {
    const { dismiss } = init.current;
    dismiss(id);
  }, []);

  useEffect(() => {
    const timeouts: (() => void)[] = [];
    if (notificationTimers) {
      notificationTimers.forEach(({ id, timer }) => {
        const timeoutId = setTimeout(() => {
          handleDismiss(id as string);
        }, Number(timer) * 1000);

        timeouts.push(() => clearTimeout(timeoutId));
      });
    }

    return () => timeouts.forEach((cleanup) => cleanup());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationTimers]);

  if (!notifications) return null;

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
        <Transition show={showNotification}>
          {notifications?.map((n, i) => {
            return (
              <Content
                dismissible={n.dismissible}
                id={n.id}
                type={n.type}
                content={n.content}
                key={`${n.id}-${i + 1}`}
                message={n.message}
                title={n.title}
                dismissAction={() => handleDismiss(n?.id as string)}
              />
            );
          })}
        </Transition>
      </div>
    </div>
  );
};

export default Notification;
