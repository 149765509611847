import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useMemo } from "react";
import { NotificationPayload } from "../features/app/app-slice";

interface Props extends NotificationPayload {
  dismissAction?: Function;
}

const Content = ({
  type,
  title,
  message,
  content,
  dismissible,
  dismissAction,
}: Props) => {
  const icon = useMemo<Readonly<{ [key: string]: JSX.Element | null }>>(
    () =>
      Object.freeze({
        success: (
          <CheckCircleIcon
            aria-hidden="true"
            className="size-6 text-green-400"
          />
        ),
        error: (
          <ExclamationTriangleIcon
            aria-hidden="true"
            className="size-6 text-red-400"
          />
        ),
        warning: (
          <ExclamationCircleIcon
            aria-hidden="true"
            className="size-6 text-yellow-400"
          />
        ),
        info: null,
      }),
    []
  );

  const iconToDisplay = useMemo(() => icon[type], [icon, type]);

  return (
    <>
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white ring-1 shadow-lg ring-black/5 transition data-closed:opacity-0 data-enter:transform data-enter:duration-300 data-enter:ease-out data-closed:data-enter:translate-y-2 data-leave:duration-100 data-leave:ease-in data-closed:data-enter:sm:translate-x-2 data-closed:data-enter:sm:translate-y-0">
        <div className="p-4">
          <div className="flex items-start">
            {iconToDisplay && <div className="shrink-0">{iconToDisplay}</div>}
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {title && (
                <p className="text-sm font-medium text-gray-900">{title}</p>
              )}
              {message && (
                <p className="mt-1 text-sm text-gray-500">{message}</p>
              )}
            </div>
            {dismissible && (
              <div className="ml-4 flex shrink-0">
                <button
                  type="button"
                  onClick={dismissAction ? () => dismissAction() : () => null}
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:outline-hidden"
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon aria-hidden="true" className="size-5" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
