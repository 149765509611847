import { Transition } from "@headlessui/react";
import { Fragment, SetStateAction, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../components/Logo/Logo";
import useCart from "../../../hooks/useCart";
import { useAppSelector } from "../../../app/hooks";
import SignInCreateAccount from "./SignInCreateAccount";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import SignIn from "../../../components/SignIn/SignIn";
import Modal from "../../../components/Modal";
import CartModal from "./CartModal";

type Props = {};
interface SearchComponentProps {
  onSearch: (searchTerm: string) => void;
}

const HeroSection = ({ onSearch }: SearchComponentProps) => {
  const { userInfo } = useAppSelector((state) => state.auth);
  // const { customer, loading } = useCustomer({
  //   filter: {
  //     id: { eq: userInfo?.id },
  //   },
  // });
  // console.log(customer);
  const { cartLength, cartItems } = useCart();
  const [cartPath, setCartPath] = useState("");
  useEffect(() => {
    const hasDigital = cartItems.some((item) => item.productType === "Digital");
    const hasPhysical = cartItems.some(
      (item) => item.productType === "Physical"
    );

    if (hasDigital && hasPhysical) {
      setCartPath("Mixed");
    } else if (hasDigital) {
      setCartPath("/order-digital-product");
    } else if (hasPhysical) {
      setCartPath("/cart");
    }
  }, [cartItems]);
  const [openSignInModal, setSignInModal] = useState(false);
  const [openCartModal, setCartModal] = useState(false);
  const navigate = useNavigate();
  const [isShowing, setIsShowing] = useState(false);

  const [searchInput, setSearchInput] = useState("");

  const handleInputChange = (e: any) => {
    setSearchInput(e.target.value);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    // onSearch(searchInput);
    localStorage.setItem("searchTerm", searchInput);
  };

  // useEffect(() => {
  //   localStorage.setItem('searchTerm', searchInput);
  // }, [searchInput]);

  return (
    <Fragment>
      <Transition.Root show={isShowing}>
        <div
          className="relative z-40 lg:hidden"
          role="dialog"
          aria-modal="true"
        >
          <Transition.Child
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25"></div>
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              {/* <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      --> */}
              <div className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
                <div className="flex px-4 pt-5 pb-2">
                  <button
                    onClick={() => setIsShowing(false)}
                    type="button"
                    className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                  >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>

                {/* <!-- Links --> */}

                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  {/* <div className="flow-root">
                    <button className="-m-2 block p-2 font-medium text-gray-900">
                      Create an account
                    </button>
                  </div> */}
                  {/* <div className="flow-root">
                    <Link
                      to={{
                        pathname: "/signin",
                      }}
                    ></Link>
                    <button className="-m-2 block p-2 font-medium text-gray-900">
                      Sign in
                    </button>

                  </div> */}
                </div>

                <div className="space-y-6 border-t border-gray-200 py-6 px-4">
                  {/* <!-- Currency selector --> */}
                  <form>
                    <div className="inline-block">
                      <label htmlFor="mobile-currency" className="sr-only">
                        Currency
                      </label>
                      <div className="group relative -ml-2 rounded-md border-transparent focus-within:ring-2 focus-within:ring-white">
                        <select
                          id="mobile-currency"
                          name="currency"
                          className="flex items-center rounded-md border-transparent bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-gray-700 focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-800"
                        >
                          <option>CAD</option>

                          <option>USD</option>

                          <option>AUD</option>

                          <option>EUR</option>

                          <option>GBP</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                          <svg
                            className="h-5 w-5 text-gray-500"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition.Root>

      {/* Actual Hero Section */}
      <div className="relative bg-white">
        {/* <!-- Decorative image and overlay --> */}

        {/* <!-- Navigation --> */}
        <header className="relative z-10 ">
          <nav aria-label="Top">
            {/* <!-- Top navigation --> */}
            <div className="bg-gray-900">
              <div className="mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                {/* <!-- Currency selector --> */}
                <form>
                  <div>
                    <div className="group relative -ml-2 rounded-md border-transparent bg-gray-900 focus-within:ring-2 focus-within:ring-white">
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center"></div>
                    </div>
                  </div>
                </form>
                {/* Sign In Create account */}
                {!userInfo ? (
                  <div></div>
                ) : (
                  <div className=" text-sm ml-56 mt-0.5 lg:absolute lg:right-0 lg:mr-64 pb-1 font-medium text-white lg:block">
                    <Link to="/my-orders">My orders</Link>
                  </div>
                )}
                <SignInCreateAccount />
              </div>
            </div>

            {/* <!-- Secondary navigation --> */}
            <div className="bg-white bg-opacity-10 backdrop-blur-md backdrop-filter">
              <div className="mx-auto max-w-7xl px-9 lg:px-8">
                <div>
                  <div className="flex h-16 items-center justify-between">
                    {/* <!-- Logo (lg+) --> */}
                    <div className="hidden lg:flex lg:flex-1  lg:items-center">
                      <button>
                        <Logo />
                      </button>
                    </div>

                    <div className="hidden h-full lg:flex">
                      {/* <!-- Flyout menus --> */}
                    </div>

                    {/* <!-- Logo (lg-) --> */}
                    <button className="lg:hidden mr-2">
                      <Logo />
                    </button>

                    <div className="flex flex-1 items-center justify-end">
                      {/* <button className="hidden text-sm font-medium text-black lg:block">
                        Search
                      </button> */}
                      {/* <form
                      onSubmit={handleSearch}
                      className="flex flex-row"
                      >
                       <input type="text"
                       placeholder="Search products"
                       className="flex lg:w-72 sm:24 px-2 sm:ml-2 border active:border-gray-400  h-10 rounded-md text-sm"
                       onChange={handleInputChange}
                       value={searchInput}
                       />
                      <button
                      type="submit"
                      className=" text-sm font-medium bg-orange-500 text-white mx-2 px-2 py-2 rounded-md lg:block">
                        Search
                      </button>
                      </form> */}

                      <div className="flex items-center lg:ml-8">
                        {/* <!-- Account section --> */}

                        {/* {!userInfo?(
                          <div></div>
                        ):( */}
                        <div className=" text-sm mr-9 font-medium text-black lg:block">
                          <Link to="/Orders">Track order</Link>
                        </div>
                        {/* )} */}

                        {/* <!-- Cart --> */}
                        <div className="mr-6 lg:mr-0 flow-root lg:ml-8">
                          <Link
                            to="/cart"
                            // onClick={(e) => {
                            //   if (userInfo) return;
                            //   e.preventDefault();
                            //   setCartModal(true);
                            //   // showNotification({
                            //   //   title: "Sign in Required",
                            //   //   message:
                            //   //     "Hey there, you would have to either sign in or create an account to continue",
                            //   // });
                            // }}
                            className="group -m-2 flex items-center p-2"
                          >
                            <svg
                              className="h-6 w-6 flex-shrink-0 text-black"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                              />
                            </svg>
                            <span className="mb-3 absolute ml-4 text-center w-4 h-4 text-white rounded-full text-xs font-medium bg-pink-500">
                              {cartLength}
                            </span>
                            <span className="sr-only">
                              items in cart, view bag
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
      <Modal showCloseIcon show={openSignInModal} setShow={setSignInModal}>
        <SignIn setShowSignIn={setSignInModal} />
      </Modal>
      <Modal showCloseIcon show={openCartModal} setShow={setCartModal}>
        <CartModal setShow={setCartModal} />
      </Modal>
    </Fragment>
  );
};

export default HeroSection;
