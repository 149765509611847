import { useQuery } from "@apollo/client";
import {
  GetStorefrontCartQuery,
  GetStorefrontCartQueryVariables,
} from "../graphql/queries/__generated__/queries";
import { GET_STOREFRONT_CART } from "../graphql/queries/queries";

export const useGetStorefrontCart = (
  variables: GetStorefrontCartQueryVariables
) => {
  const { data, loading, error } = useQuery<
    GetStorefrontCartQuery,
    GetStorefrontCartQueryVariables
  >(GET_STOREFRONT_CART, {
    fetchPolicy: "no-cache",
    variables,
  });
  return {
    cart: data?.getStorefrontCart,
    fetching: loading,
    error,
  };
};
